import type { ModuleWithProviders, OnDestroy } from '@angular/core';
import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
  NgModule,
} from '@angular/core';
import { Auth } from '@freelancer/auth';
import type {
  BackendErrorResponse,
  BackendSuccessResponse,
  ResponseData,
} from '@freelancer/freelancer-http';
import { executeSchedule } from '@freelancer/operators-utils';
import { assertNever, isDefined } from '@freelancer/utils';
import { Store } from '@ngrx/store';
import { ErrorCodeApi } from 'api-typings/errors/errors';
import type { Observable } from 'rxjs';
import { Subscription, asyncScheduler, combineLatest, of } from 'rxjs';
import {
  delay,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import type {
  CollectionActions,
  DeleteAction,
  DeleteErrorAction,
  DeleteRequestPayload,
  PushAction,
  PushErrorAction,
  PushRequestPayload,
  SetAction,
  SetErrorAction,
  SetRequestPayload,
  UpdateAction,
  UpdateErrorAction,
  UpdateRequestPayload,
} from './actions';
import type { ApiFetchResponse } from './api-http.service';
import { ApiHttp } from './api-http.service';
import { DATASTORE_CONFIG, NON_FETCH_REQUEST_CONFIG } from './datastore.config';
import { DatastoreConfig, NonFetchRequestConfig } from './datastore.interface';
import type { RecursivePartial } from './helpers';
import { DatastoreMissingModuleError } from './missing-module-error';
import type { StoreBackendInterface } from './store-backend.interface';
import type {
  BackendDeleteRequest,
  BackendDeleteResponse,
  BackendFetchRequest,
  BackendPushRequest,
  BackendPushResponse,
  BackendSetRequest,
  BackendSetResponse,
  BackendUpdateRequest,
  BackendUpdateResponse,
  ExtractIdFunction,
} from './store-backend.model';
import { pluckDocumentFromRawStore } from './store.helpers';
import type {
  DatastoreCollectionType,
  DatastoreDeleteCollectionType,
  DatastoreFetchCollectionType,
  DatastorePushCollectionType,
  DatastoreSetCollectionType,
  DatastoreUpdateCollectionType,
  Ordering,
  Path,
  PushDocumentType,
  RawQuery,
  Reference,
  SetDocumentType,
  SingleOrdering,
  StoreState,
} from './store.model';

type FetchRequestFactory<
  C extends DatastoreCollectionType & DatastoreFetchCollectionType,
> = (
  authUid: string,
  /** The document IDs passed to a `datastore.document` call */
  ids: readonly string[] | undefined,
  /** The query passed to a `datastore.collection` call or `document` call by secondary ID */
  query: RawQuery<C['DocumentType']> | undefined,
  order: Ordering<C> | undefined,
  resourceGroup: C['ResourceGroup'] | undefined,
) => BackendFetchRequest<C>;

type PushRequestFactory<
  C extends DatastoreCollectionType & DatastorePushCollectionType,
> = (
  authUid: string,
  document: PushDocumentType<C>,
  extra: { readonly [index: string]: string | number | object } | undefined,
) => BackendPushRequest<C>;

type SetRequestFactory<
  C extends DatastoreCollectionType & DatastoreSetCollectionType,
> = (authUid: string, document: SetDocumentType<C>) => BackendSetRequest<C>;

type UpdateRequestFactory<
  C extends DatastoreCollectionType & DatastoreUpdateCollectionType,
> = (
  authUid: string,
  delta: RecursivePartial<C['DocumentType']>,
  originalDocument: C['DocumentType'],
) => BackendUpdateRequest<C>;

type DeleteRequestFactory<
  C extends DatastoreCollectionType & DatastoreDeleteCollectionType,
> = (
  authUid: string,
  id: string | number,
  originalDocument: C['DocumentType'],
) => BackendDeleteRequest<C>;

/**
 * This type is strange as it seeks to enforce that the backend factory
 * implements each method if and only iff it is specified in the collection
 * type. If not you need to specify `undefined`.
 *
 * The `C['Backend']['Fetch'] extends never` is needed to check if it's actually
 * there, and the `C extends DatastoreFetchCollectionType` is necessary to let
 * TypeScript know it is there. Not sure why we need both :(
 */
export interface Backend<C extends DatastoreCollectionType> {
  readonly fetch: C['Backend']['Fetch'] extends never
    ? undefined
    : C extends DatastoreFetchCollectionType
      ? FetchRequestFactory<C>
      : undefined;

  readonly push: C['Backend']['Push'] extends never
    ? undefined
    : C extends DatastorePushCollectionType
      ? PushRequestFactory<C>
      : undefined;

  readonly set: C['Backend']['Set'] extends never
    ? undefined
    : C extends DatastoreSetCollectionType
      ? SetRequestFactory<C>
      : undefined;

  readonly update: C['Backend']['Update'] extends never
    ? undefined
    : C extends DatastoreUpdateCollectionType
      ? UpdateRequestFactory<C>
      : undefined;

  readonly remove: C['Backend']['Delete'] extends never
    ? undefined
    : C extends DatastoreDeleteCollectionType
      ? DeleteRequestFactory<C>
      : undefined;

  readonly defaultOrder: SingleOrdering<C> | Ordering<C>;

  readonly maxBatchSize?: number;

  /** Can you subscribe to these events from the websocket? */
  readonly isSubscribable?: true;
}

export const BACKEND_DEFAULT_BATCH_SIZE = 100;

export type BackendConfigs = { [K in string]?: Backend<any> }; // FIXME: T267853 -

@Injectable()
export class StoreBackend implements StoreBackendInterface, OnDestroy {
  private backendConfigs: BackendConfigs = {};

  /**
   * This buffer keeps track of previously seen non-fetch requests and rate limits
   * duplicate requests within a sliding window
   **/
  private requestBuffer: {
    [key in 'POST' | 'PUT' | 'DELETE']: readonly string[];
  } = {
    POST: [],
    PUT: [],
    DELETE: [],
  };
  private subscriptions = new Subscription();

  constructor(
    private store$: Store<StoreState>,
    private apiHttp: ApiHttp,
    private auth: Auth,
    private errorHandler: ErrorHandler,
    @Inject(DATASTORE_CONFIG) private datastoreConfig: DatastoreConfig,
    @Inject(NON_FETCH_REQUEST_CONFIG)
    private nonFetchRequestConfig: NonFetchRequestConfig,
  ) {}

  /**
   * Checks if the backend config (*.backend.ts) for a given collection is available.
   * It is only available if the BackendFeatureModule (or DatastoreFeatureModule)
   * for that collection has been imported.
   *
   * Note that this does not check if the @ngrx/store feature module for that
   * collection has been imported. This is only possible when we have access to
   * store state, i.e. upon subscription to the store. However, since the both
   * the store and backend feature modules are imported together in the various
   * `DatastoreXModule` modules, checking one of these is equivalent to checking
   * the other.
   */
  isFeatureLoaded<C extends DatastoreCollectionType>(
    collection: any, // FIXME: T267853 -
  ): boolean {
    return collection in this.backendConfigs;
  }

  defaultOrder<C extends DatastoreCollectionType>(
    collection: C['Name'],
  ): Ordering<C> {
    const config = this.backendConfigs[collection as any] as unknown as
      | Backend<C>
      | undefined; // FIXME: T267853 -
    if (!config) {
      throw new DatastoreMissingModuleError(collection);
    }
    return Array.isArray(config.defaultOrder)
      ? config.defaultOrder
      : ([config.defaultOrder] as Ordering<C>);
  }

  batchSize<C extends DatastoreCollectionType>(ref: Reference<C>): number {
    const config = this.backendConfigs[ref.path.collection];
    return (config && config.maxBatchSize) || BACKEND_DEFAULT_BATCH_SIZE;
  }

  isSubscribable<C extends DatastoreCollectionType>(
    collectionName: C['Name'],
  ): boolean {
    const config = this.backendConfigs[collectionName];
    return (config && config.isSubscribable) || false;
  }

  fetch<C extends DatastoreCollectionType & DatastoreFetchCollectionType>(
    ref: Reference<C>,
    resourceGroup: C['ResourceGroup'] | undefined,
    isRefetch: boolean = false,
  ): Observable<ApiFetchResponse<C>> {
    const { path, query, order } = ref;
    const type = path.collection;
    return combineLatest([
      of(this.backendConfigs[type]).pipe(
        map(config => {
          if (!config) {
            throw new DatastoreMissingModuleError(type);
          }
          return config.fetch;
        }),
        filter(isDefined),
        map((fetch: FetchRequestFactory<C>) =>
          fetch(path.authUid, path.ids, query, order, resourceGroup),
        ),
      ),
      this.auth.isLoggedIn().pipe(take(1)),
    ]).pipe(
      switchMap(([request, isLoggedIn]) => {
        const actualRequest = {
          ...request,
          params: {
            ...request.params,
            ...(isRefetch ? { __is_store_refetch__: 'true' } : {}),
          },
          // Don't throw errors when a fetch fails with NOT_FOUND.
          // This is generally expected and not cause for an exception.
          errorWhitelist: isLoggedIn
            ? ['NOT_FOUND']
            : ['NOT_FOUND', 'UNAUTHORIZED'],
        };
        return this.apiHttp.fetch(actualRequest, query);
      }),
    );
  }

  push<C extends DatastoreCollectionType & DatastorePushCollectionType>(
    ref: Reference<C>,
    document: PushDocumentType<C>,
    extra?: { readonly [index: string]: string | number | object },
  ): Observable<BackendPushResponse<C>> {
    const { path, query } = ref;
    const type = path.collection;

    return of(this.backendConfigs[type]).pipe(
      map(config => {
        if (!config) {
          throw new DatastoreMissingModuleError(type);
        }
        return config.push;
      }),
      filter(isDefined),
      map((push: PushRequestFactory<C>) => {
        const request = push(path.authUid, document, extra);
        return {
          request,
          payload: {
            type,
            ref,
            document,
            rawRequest: request.payload,
          },
        };
      }),
      // We want the Datastore actions to be asynchronous, as updating an
      // object on rendering is a legitimate use case (e.g. mark as read), and
      // the actions results being synchronous would trigger
      // "ExpressionChangedAfterItHasBeenChecked" errors
      delay(0, this.datastoreConfig.backendScheduler),
      tap(({ payload }) => {
        const action: CollectionActions<C> = {
          type: `API_PUSH`,
          payload,
        } as C extends DatastorePushCollectionType ? PushAction<C> : never;
        this.store$.dispatch(action);
      }),
      switchMap(({ request, payload }) => {
        const apiResult$ = this.requestApi(
          request,
          payload,
          'POST',
          'PUSH',
        ).pipe(
          map(result => ({ result, payload, extractId: request.extractId })),
        );
        return apiResult$;
      }),
      map(({ payload, result, extractId }) =>
        this.sendActions(
          { type: 'PUSH', payload },
          path,
          query,
          result,
          extractId,
        ),
      ),
    );
  }

  set<C extends DatastoreCollectionType & DatastoreSetCollectionType>(
    ref: Reference<C>,
    id: number | string,
    document: SetDocumentType<C>,
  ): Observable<BackendSetResponse<C>> {
    const { path, query } = ref;
    const type = path.collection;

    return of(this.backendConfigs[type]).pipe(
      map(config => {
        if (!config) {
          throw new DatastoreMissingModuleError(type);
        }
        return config.set;
      }),
      filter(isDefined),
      map((set: SetRequestFactory<C>) => set),
      withLatestFrom(this.store$),
      map(([set, storeState]) => {
        const originalDocument = pluckDocumentFromRawStore(
          storeState,
          path,
          id,
        );
        const request = set(path.authUid, document);
        return {
          request,
          payload: {
            type,
            ref,
            document,
            originalDocument,
            rawRequest: request.payload,
          },
        };
      }),
      // We want the Datastore actions to be asynchronous, as updating an
      // object on rendering is a legitimate use case (e.g. mark as read), and
      // the actions results being synchronous would trigger
      // "ExpressionChangedAfterItHasBeenChecked" errors
      delay(0, this.datastoreConfig.backendScheduler),
      tap(({ payload }) => {
        const action: CollectionActions<C> = {
          type: `API_SET`,
          payload,
        } as C extends DatastoreSetCollectionType ? SetAction<C> : never;
        this.store$.dispatch(action);
      }),
      switchMap(({ request, payload }) =>
        this.requestApi(request, payload, 'POST', 'SET').pipe(
          map(result => ({ result, payload })),
        ),
      ),
      map(({ payload, result }) =>
        this.sendActions({ type: 'SET', payload }, path, query, result),
      ),
    );
  }

  update<C extends DatastoreCollectionType & DatastoreUpdateCollectionType>(
    ref: Reference<C>,
    id: number | string,
    delta: RecursivePartial<C['DocumentType']>,
  ): Observable<BackendUpdateResponse<C>> {
    const { path } = ref;
    const type = path.collection;

    return of(this.backendConfigs[type]).pipe(
      map(config => {
        if (!config) {
          throw new DatastoreMissingModuleError(type);
        }
        return config.update;
      }),
      filter(isDefined),
      map((set: UpdateRequestFactory<C>) => set),
      withLatestFrom(this.store$),
      map(([update, storeState]) => {
        const originalDocument = pluckDocumentFromRawStore(
          storeState,
          path,
          id,
        );
        if (originalDocument === undefined) {
          throw new Error('Missing original document');
        }
        const request = update(path.authUid, delta, originalDocument);
        return {
          request,
          payload: {
            type,
            ref,
            delta,
            rawRequest: request.payload,
            originalDocument,
          },
        };
      }),
      // We want the Datastore actions to be asynchronous, as updating an
      // object on rendering is a legitimate use case (e.g. mark as read), and
      // the actions results being synchronous would trigger
      // "ExpressionChangedAfterItHasBeenChecked" errors
      delay(0, this.datastoreConfig.backendScheduler),
      tap(({ payload }) => {
        const action: CollectionActions<C> = {
          type: `API_UPDATE`,
          payload,
        } as C extends DatastoreUpdateCollectionType ? UpdateAction<C> : never;
        this.store$.dispatch(action);
      }),
      switchMap(({ request, payload }) =>
        this.requestApi(request, payload, request.method, 'UPDATE').pipe(
          map(result => ({ result, payload })),
        ),
      ),
      map(({ payload, result }) =>
        this.sendActions(
          { type: 'UPDATE', payload },
          payload.ref.path,
          payload.ref.query,
          result,
        ),
      ),
    );
  }

  delete<C extends DatastoreCollectionType & DatastoreDeleteCollectionType>(
    ref: Reference<C>,
    id: number | string,
  ): Observable<BackendDeleteResponse<C>> {
    const { path } = ref;
    const type = path.collection;

    return of(this.backendConfigs[type]).pipe(
      map(config => {
        if (!config) {
          throw new DatastoreMissingModuleError(type);
        }
        return config.remove;
      }),
      filter(isDefined),
      map((set: DeleteRequestFactory<C>) => set),
      withLatestFrom(this.store$),
      map(([del, storeState]) => {
        const originalDocument = pluckDocumentFromRawStore(
          storeState,
          path,
          id,
        );
        if (originalDocument === undefined) {
          throw new Error('Missing original document');
        }
        const request = del(path.authUid, id, originalDocument);
        return {
          request,
          payload: {
            type,
            ref,
            rawRequest: request.payload,
            originalDocument,
          },
        };
      }),
      // We want the Datastore actions to be asynchronous, as updating an
      // object on rendering is a legitimate use case (e.g. mark as read), and
      // the actions results being synchronous would trigger
      // "ExpressionChangedAfterItHasBeenChecked" errors
      delay(0, this.datastoreConfig.backendScheduler),
      tap(({ payload }) => {
        const action: CollectionActions<C> = {
          type: `API_DELETE`,
          payload,
        } as C extends DatastoreDeleteCollectionType ? DeleteAction<C> : never;
        this.store$.dispatch(action);
      }),
      switchMap(({ request, payload }) =>
        this.requestApi(request, payload, request.method, 'DELETE').pipe(
          map(result => ({ result, payload })),
        ),
      ),
      map(({ payload, result }) =>
        this.sendActions(
          { type: 'DELETE', payload },
          payload.ref.path,
          payload.ref.query,
          result,
        ),
      ),
    );
  }

  requestApi<C extends DatastoreCollectionType & DatastorePushCollectionType>(
    request: BackendPushRequest<C>,
    payload: {
      type: string;
      ref: Reference<C>;
      document: PushDocumentType<C>;
      rawRequest: C['Backend']['Push']['PayloadType'];
    },
    requestMethod: 'POST',
    baseAction: 'PUSH',
  ): Observable<
    | ResponseData<
        C['Backend']['Push']['ReturnType'],
        C['Backend']['Push']['ErrorType']
      >
    | BackendErrorResponse<ErrorCodeApi.TOO_MANY_REQUESTS>
  >;
  requestApi<C extends DatastoreCollectionType & DatastoreSetCollectionType>(
    request: BackendSetRequest<C>,
    payload: {
      type: string;
      ref: Reference<C>;
      document: SetDocumentType<C>;
      rawRequest: C['Backend']['Set']['PayloadType'];
      originalDocument: C['DocumentType'] | undefined;
    },
    requestMethod: 'POST',
    baseAction: 'SET',
  ): Observable<
    | ResponseData<
        C['Backend']['Set']['ReturnType'],
        C['Backend']['Set']['ErrorType']
      >
    | BackendErrorResponse<ErrorCodeApi.TOO_MANY_REQUESTS>
  >;
  requestApi<C extends DatastoreCollectionType & DatastoreUpdateCollectionType>(
    request: BackendUpdateRequest<C>,
    payload: {
      type: string;
      ref: Reference<C>;
      delta: RecursivePartial<C['DocumentType']>;
      rawRequest: C['Backend']['Update']['PayloadType'];
      originalDocument: C['DocumentType'];
    },
    requestMethod: 'POST' | 'PUT',
    baseAction: 'UPDATE',
  ): Observable<
    | ResponseData<
        C['Backend']['Update']['ReturnType'],
        C['Backend']['Update']['ErrorType']
      >
    | BackendErrorResponse<ErrorCodeApi.TOO_MANY_REQUESTS>
  >;
  requestApi<C extends DatastoreCollectionType & DatastoreDeleteCollectionType>(
    request: BackendDeleteRequest<C>,
    payload: {
      type: string;
      ref: Reference<C>;
      rawRequest: C['Backend']['Delete']['PayloadType'];
      originalDocument: C['DocumentType'];
    },
    requestMethod: 'POST' | 'PUT' | 'DELETE',
    baseAction: 'DELETE',
  ): Observable<
    | ResponseData<
        C['Backend']['Delete']['ReturnType'],
        C['Backend']['Delete']['ErrorType']
      >
    | BackendErrorResponse<ErrorCodeApi.TOO_MANY_REQUESTS>
  >;
  requestApi<
    C extends DatastoreCollectionType &
      DatastorePushCollectionType &
      DatastoreSetCollectionType &
      DatastoreUpdateCollectionType &
      DatastoreDeleteCollectionType,
  >(
    request:
      | BackendPushRequest<C>
      | BackendSetRequest<C>
      | BackendUpdateRequest<C>
      | BackendDeleteRequest<C>,
    payload: {
      type: string;
      ref: Reference<C>;
      rawRequest:
        | C['Backend']['Push']['PayloadType']
        | C['Backend']['Set']['PayloadType']
        | C['Backend']['Update']['PayloadType']
        | C['Backend']['Delete']['PayloadType'];
      delta?: RecursivePartial<C['DocumentType']>;
      originalDocument?: C['DocumentType'] | undefined;
      document?: PushDocumentType<C> | SetDocumentType<C>;
    },
    requestMethod: 'POST' | 'PUT' | 'DELETE',
    baseAction: 'PUSH' | 'SET' | 'UPDATE' | 'DELETE',
  ): Observable<
    | ResponseData<
        C['Backend']['Push']['ReturnType'],
        C['Backend']['Push']['ErrorType']
      >
    | ResponseData<
        C['Backend']['Set']['ReturnType'],
        C['Backend']['Set']['ErrorType']
      >
    | ResponseData<
        C['Backend']['Update']['ReturnType'],
        C['Backend']['Update']['ErrorType']
      >
    | ResponseData<
        C['Backend']['Delete']['ReturnType'],
        C['Backend']['Delete']['ErrorType']
      >
    | BackendErrorResponse<ErrorCodeApi.TOO_MANY_REQUESTS>
  > {
    const payloadString = JSON.stringify(request.payload);
    const idString = `${request.endpoint}-${payloadString}`;
    const scheduler = this.datastoreConfig.backendScheduler || asyncScheduler;

    // If identical request in the sliding window, then rate limit it by mimicking API error
    // We'll skip calling the API and just return early with this error.
    if (this.requestBuffer[requestMethod].includes(idString)) {
      const rateLimitResult: BackendErrorResponse<ErrorCodeApi.TOO_MANY_REQUESTS> =
        {
          status: 'error',
          requestId: undefined,
          errorCode: ErrorCodeApi.TOO_MANY_REQUESTS,
        };

      this.errorHandler.handleError(
        new Error(
          `Failed to make ${requestMethod} request to endpoint ${request.endpoint} in ${baseAction} method to collection ${payload.type}. Too many duplicate requests within ${this.nonFetchRequestConfig.windowTime}ms.`,
        ),
      );

      return of(rateLimitResult);
    }
    // Call the API if not frontend rate limited and add to the sliding window.
    let apiResponse$;
    let updateRequest: BackendUpdateRequest<C> | BackendDeleteRequest<C>;
    let deleteRequest: BackendDeleteRequest<C>;
    switch (requestMethod) {
      case 'POST':
        apiResponse$ = this.apiHttp.post(request);
        break;
      case 'PUT':
        updateRequest = request as
          | BackendUpdateRequest<C>
          | BackendDeleteRequest<C>;
        apiResponse$ = this.apiHttp.put(updateRequest);
        break;
      case 'DELETE':
        deleteRequest = request as BackendDeleteRequest<C>;
        apiResponse$ = this.apiHttp.delete(deleteRequest);
        break;
      default:
        return assertNever(requestMethod);
    }

    this.requestBuffer[requestMethod] = [
      ...this.requestBuffer[requestMethod],
      idString,
    ];
    executeSchedule(
      this.subscriptions,
      scheduler,
      () => {
        if (this.requestBuffer[requestMethod].length > 0) {
          const [, ...remainingBuffer] = this.requestBuffer[requestMethod];
          this.requestBuffer[requestMethod] = remainingBuffer;
        }
      },
      this.nonFetchRequestConfig.windowTime,
    );

    return apiResponse$;
  }

  private sendActions<
    C extends DatastoreCollectionType & DatastorePushCollectionType,
  >(
    baseAction: {
      readonly type: 'PUSH';
      readonly payload: PushRequestPayload<C>;
    },
    path: Path<C>,
    query: RawQuery<C['DocumentType']> | undefined,
    data: ResponseData<
      C['Backend']['Push']['ReturnType'],
      C['Backend']['Push']['ErrorType']
    >,
    extractId?: ExtractIdFunction<C>,
  ): BackendPushResponse<C>;
  private sendActions<
    C extends DatastoreCollectionType & DatastoreSetCollectionType,
  >(
    baseAction: {
      readonly type: 'SET';
      readonly payload: SetRequestPayload<C>;
    },
    path: Path<C>,
    query: RawQuery<C['DocumentType']> | undefined,
    data: ResponseData<
      C['Backend']['Set']['ReturnType'],
      C['Backend']['Set']['ErrorType']
    >,
  ): BackendSetResponse<C>;
  private sendActions<
    C extends DatastoreCollectionType & DatastoreUpdateCollectionType,
  >(
    baseAction: {
      readonly type: 'UPDATE';
      readonly payload: UpdateRequestPayload<C>;
    },
    path: Path<C>,
    query: RawQuery<C['DocumentType']> | undefined,
    data: ResponseData<
      C['Backend']['Update']['ReturnType'],
      C['Backend']['Update']['ErrorType']
    >,
  ): BackendUpdateResponse<C>;
  private sendActions<
    C extends DatastoreCollectionType & DatastoreDeleteCollectionType,
  >(
    baseAction: {
      readonly type: 'DELETE';
      readonly payload: DeleteRequestPayload<C>;
    },
    path: Path<C>,
    query: RawQuery<C['DocumentType']> | undefined,
    data: ResponseData<
      C['Backend']['Delete']['ReturnType'],
      C['Backend']['Delete']['ErrorType']
    >,
  ): ResponseData<
    C['Backend']['Delete']['ReturnType'],
    C['Backend']['Delete']['ErrorType']
  >;
  private sendActions<
    C extends DatastoreCollectionType &
      DatastorePushCollectionType &
      DatastoreSetCollectionType &
      DatastoreUpdateCollectionType &
      DatastoreDeleteCollectionType,
  >(
    baseAction:
      | { readonly type: 'PUSH'; readonly payload: PushRequestPayload<C> }
      | { readonly type: 'SET'; readonly payload: SetRequestPayload<C> }
      | { readonly type: 'UPDATE'; readonly payload: UpdateRequestPayload<C> }
      | { readonly type: 'DELETE'; readonly payload: DeleteRequestPayload<C> },
    path: Path<C>,
    query: RawQuery<C['DocumentType']> | undefined,
    data:
      | ResponseData<
          C['Backend']['Push']['ReturnType'],
          C['Backend']['Push']['ErrorType']
        >
      | ResponseData<
          C['Backend']['Set']['ReturnType'],
          C['Backend']['Set']['ErrorType']
        >
      | ResponseData<
          C['Backend']['Update']['ReturnType'],
          C['Backend']['Update']['ErrorType']
        >
      | ResponseData<
          C['Backend']['Delete']['ReturnType'],
          C['Backend']['Delete']['ErrorType']
        >,
    extractId?: ExtractIdFunction<C>,
  ):
    | BackendPushResponse<C>
    | BackendSetResponse<C>
    | BackendUpdateResponse<C>
    | BackendDeleteResponse<C> {
    switch (data.status) {
      case 'success': {
        const action: CollectionActions<C> =
          baseAction.type === 'PUSH'
            ? ({
                type: 'API_PUSH_SUCCESS',
                payload: {
                  type: baseAction.payload.type,
                  ref: baseAction.payload.ref,
                  document: baseAction.payload.document,
                  rawRequest: baseAction.payload.rawRequest,
                  result: data.result,
                },
              } as CollectionActions<C>)
            : baseAction.type === 'SET'
              ? ({
                  type: 'API_SET_SUCCESS',
                  payload: {
                    type: baseAction.payload.type,
                    ref: baseAction.payload.ref,
                    document: baseAction.payload.document,
                    originalDocument: baseAction.payload.originalDocument,
                    rawRequest: baseAction.payload.rawRequest,
                    result: data.result,
                  },
                } as CollectionActions<C>)
              : baseAction.type === 'UPDATE'
                ? ({
                    type: 'API_UPDATE_SUCCESS',
                    payload: {
                      type: baseAction.payload.type,
                      ref: baseAction.payload.ref,
                      delta: baseAction.payload.delta,
                      originalDocument: baseAction.payload.originalDocument,
                      rawRequest: baseAction.payload.rawRequest,
                      result: data.result,
                    },
                  } as CollectionActions<C>)
                : ({
                    type: 'API_DELETE_SUCCESS',
                    payload: {
                      type: baseAction.payload.type,
                      ref: baseAction.payload.ref,
                      originalDocument: baseAction.payload.originalDocument,
                      rawRequest: baseAction.payload.rawRequest,
                      result: data.result,
                    },
                  } as CollectionActions<C>);
        this.store$.dispatch(action);
        return baseAction.type === 'PUSH'
          ? ({
              status: 'success',
              id: extractId ? extractId(data.result) : (data.result as any)?.id,
            } as BackendSuccessResponse)
          : { status: 'success' };
      }
      default: {
        const action: CollectionActions<C> =
          baseAction.type === 'PUSH'
            ? ({
                type: 'API_PUSH_ERROR',
                payload: baseAction.payload,
              } as C extends DatastorePushCollectionType
                ? PushErrorAction<C>
                : never)
            : baseAction.type === 'SET'
              ? ({
                  type: 'API_SET_ERROR',
                  payload: baseAction.payload,
                } as C extends DatastoreSetCollectionType
                  ? SetErrorAction<C>
                  : never)
              : baseAction.type === 'UPDATE'
                ? ({
                    type: 'API_UPDATE_ERROR',
                    payload: baseAction.payload,
                  } as C extends DatastoreUpdateCollectionType
                    ? UpdateErrorAction<C>
                    : never)
                : ({
                    type: 'API_DELETE_ERROR',
                    payload: baseAction.payload,
                  } as C extends DatastoreDeleteCollectionType
                    ? DeleteErrorAction<C>
                    : never);
        this.store$.dispatch(action);
        return data;
      }
    }
  }

  addFeature<C extends DatastoreCollectionType>(
    collectionName: any, // FIXME: T267853 -
    requestFactory: BackendConfigs[any], // FIXME: T267853 -
  ): void {
    this.backendConfigs[collectionName] = requestFactory;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export const BACKEND_COLLECTIONS =
  new InjectionToken<BackendCollectionsProvider>('Backend Collections');

export const BACKEND_CONFIGS = new InjectionToken<BackendConfigsProvider>(
  'Backend Configs',
);

type BackendConfigFactory<C extends DatastoreCollectionType> = () => Backend<C>;

export type BackendCollectionsProvider = readonly any[]; // FIXME: T267853 -
export type BackendConfigsProvider = readonly Backend<any>[]; // FIXME: T267853 -

@NgModule({})
export class BackendRootModule {}

@NgModule({})
export class BackendFeatureModule {
  constructor(
    storeBackend: StoreBackend,
    @Inject(BACKEND_COLLECTIONS) backendCollections: BackendCollectionsProvider,
    @Inject(BACKEND_CONFIGS) backendConfigs: BackendConfigsProvider,
  ) {
    backendCollections.map((collectionName, index) => {
      storeBackend.addFeature(collectionName, backendConfigs[index]);
    });
  }
}

@NgModule({})
export class BackendModule {
  static forRoot(): ModuleWithProviders<BackendRootModule> {
    return {
      ngModule: BackendRootModule,
      providers: [StoreBackend],
    };
  }

  static forFeature<C extends DatastoreCollectionType>(
    collectionName: C['Name'],
    configFactory: BackendConfigFactory<C>,
  ): ModuleWithProviders<BackendFeatureModule> {
    return {
      ngModule: BackendFeatureModule,
      providers: [
        {
          provide: BACKEND_COLLECTIONS,
          multi: true,
          useValue: collectionName,
        },
        {
          provide: BACKEND_CONFIGS,
          multi: true,
          useFactory: configFactory,
        },
      ],
    };
  }
}
