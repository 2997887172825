import type { ComponentRef, Renderer2 } from '@angular/core';
import {
  ApplicationRef,
  createComponent,
  EnvironmentInjector,
  Inject,
  Injectable,
  Injector,
  Optional,
  RendererFactory2,
  Type,
} from '@angular/core';
import { TimeUtils } from '@freelancer/time-utils';
import { isDefined } from '@freelancer/utils';
import { TESTING_CLOSE_ANIMATION_DISABLED } from '../ui.config';
import type { ToastAlertContainerComponentInterface } from './toast-alert-container.types';
import type {
  DynamicToastAlertOptions,
  ToastAlertComponentInterface,
} from './toast-alert.types';
import {
  TOAST_ALERT_COMPONENT,
  ToastAlertColor,
  ToastAlertType,
} from './toast-alert.types';

@Injectable({ providedIn: 'root' })
export class ToastAlertService {
  private toastAlerts: { [k: string]: ToastAlertComponentInterface } = {};
  private activeToasts: ToastAlertComponentInterface[] = [];
  private containers: ToastAlertContainerComponentInterface[] = [];
  private renderer: Renderer2;
  private autoCloseDisabled = false;
  private dynamicComponentRefs: {
    [k: string]: ComponentRef<ToastAlertComponentInterface>;
  } = {};
  private dynamicToastIds: Set<string> = new Set();

  constructor(
    rendererFactory: RendererFactory2,
    private timeUtils: TimeUtils,
    private appRef: ApplicationRef,
    private injector: Injector,
    private environmentInjector: EnvironmentInjector,
    @Inject(TOAST_ALERT_COMPONENT)
    private toastAlertComponent: Type<ToastAlertComponentInterface>,
    /** This should only be injected in UI tests */
    @Optional()
    @Inject(TESTING_CLOSE_ANIMATION_DISABLED)
    private readonly testingCloseAnimationDisabled?: boolean,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  add(toastAlertItem: ToastAlertComponentInterface, id: string): void {
    this.toastAlerts = { ...this.toastAlerts, ...{ [id]: toastAlertItem } };
  }

  remove(id: string): void {
    delete this.toastAlerts[id];

    // Clean up component reference if it exists (only for dynamic toasts)
    if (this.dynamicToastIds.has(id) && this.dynamicComponentRefs[id]) {
      this.dynamicComponentRefs[id].destroy();
      delete this.dynamicComponentRefs[id];
      this.dynamicToastIds.delete(id);
    }
  }

  registerContainer(
    toastAlertContainer: ToastAlertContainerComponentInterface,
  ): void {
    this.containers = [...this.containers, toastAlertContainer];
  }

  unregisterContainer(
    toastAlertContainer: ToastAlertContainerComponentInterface,
  ): void {
    this.containers = this.containers.filter(
      item => item !== toastAlertContainer,
    );
  }

  /**
   * Opens a toast alert by its ID
   * @param id The ID of the toast alert to open
   */
  openById(id: string): void {
    // wait a cycle before opening
    // the container might be rendered after the element
    this.timeUtils.setTimeout(() => {
      const toastAlertItem = this.toastAlerts[id];

      if (!isDefined(toastAlertItem)) {
        throw new Error(`Nonexistent toast alert ${id}`);
      }

      if (this.activeToasts.includes(toastAlertItem)) {
        toastAlertItem.resetTimer();
      } else {
        this.containers.forEach(item => {
          if (item.isElementVisible()) {
            this.renderer.appendChild(
              item.container.nativeElement,
              toastAlertItem.element,
            );
          }
        });

        this.renderer.addClass(toastAlertItem.element, 'IsActive');
        toastAlertItem.toggleVisibility('visible');
        toastAlertItem.startTimer();
        this.addToActiveToasts(toastAlertItem);
      }
    });
  }

  /**
   * Programmatically create and show a toast alert
   * @param options Toast alert options
   */
  open({
    message,
    id = `toast-alert-${Date.now()}`,
    type = ToastAlertType.INFO,
    color = ToastAlertColor.LIGHT,
    timeout = 3000,
    indefinite = false,
    closeable = false,
    action,
  }: DynamicToastAlertOptions): string {
    if (!message || message.length === 0) {
      throw new Error('Toast alert message is required');
    }

    // If the toast alert already exists, return the ID.
    if (this.toastAlerts[id]) {
      return id;
    }

    const componentRef = createComponent(this.toastAlertComponent, {
      environmentInjector: this.environmentInjector,
      elementInjector: this.injector,
    });

    componentRef.instance.id = id;
    componentRef.instance.type = type;
    componentRef.instance.color = color;
    componentRef.instance.timeout = indefinite ? undefined : timeout;
    componentRef.instance.closeable = closeable;
    componentRef.instance.content = message;
    componentRef.instance.action = action;

    this.appRef.attachView(componentRef.hostView);

    this.dynamicToastIds.add(id);
    this.dynamicComponentRefs[id] = componentRef;

    this.openById(id);

    return id;
  }

  close(id: string): void {
    const toastAlertItem = this.toastAlerts[id];

    if (toastAlertItem === undefined) {
      return;
    }
    toastAlertItem.toggleVisibility('hidden');
    this.activeToasts = this.activeToasts.filter(x => x.id !== id);
    this.repositionItems();

    if (this.testingCloseAnimationDisabled) {
      /**
       * Bypass `animationDone` callback which is being triggered later than expected in consecutive UI tests.
       * This effectively removes the close animation of the toast alert.
       */
      this.removeElement(id);
      toastAlertItem.unsubscribeTimer();
    }
  }

  addToActiveToasts(item: ToastAlertComponentInterface): void {
    this.activeToasts = [...this.activeToasts, item];
    this.repositionItems();
  }

  removeElement(id: string): void {
    const toastAlertItem = this.toastAlerts[id];

    if (toastAlertItem) {
      this.containers.forEach(item => {
        if (item.isElementVisible()) {
          this.renderer.removeChild(
            item.container.nativeElement,
            toastAlertItem.element,
          );
        }
      });

      // Remove from active toasts if it's still there
      this.activeToasts = this.activeToasts.filter(x => x.id !== id);

      // Only completely remove dynamic toasts
      if (this.dynamicToastIds.has(id)) {
        this.remove(id);
      }
    }
  }

  disableAutoCloseGlobally(): void {
    this.autoCloseDisabled = true;
  }

  isAutoCloseDisabled(): boolean {
    return this.autoCloseDisabled;
  }

  private repositionItems(): void {
    const offset = 8;
    let position = offset;

    this.activeToasts.forEach(item => {
      item.move(position);
      position += item.element.offsetHeight + offset;
    });
  }
}
