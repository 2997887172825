import type { AnimationEvent } from '@angular/animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import type { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  PLATFORM_ID,
  Renderer2,
  SkipSelf,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { slideInHorizontal, slideOutHorizontal } from '@freelancer/animations';
import { Feature, FeatureFlagsService } from '@freelancer/feature-flags';
import { Pwa } from '@freelancer/pwa';
import { FreelancerBreakpointValues } from '@freelancer/ui/breakpoints';
import { HeadingColor, HeadingType } from '@freelancer/ui/heading';
import { IconBackdrop, IconColor, IconSize } from '@freelancer/ui/icon';
import { SpinnerSize } from '@freelancer/ui/spinner';
import { StickyBehaviour } from '@freelancer/ui/sticky';
import { TextSize } from '@freelancer/ui/text';
import {
  ViewHeaderBackground,
  ViewHeaderType,
} from '@freelancer/ui/view-header';
import type { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModalColor } from './modal-color';
import { ModalSize } from './modal-size';
import { ModalService } from './modal.service';
import type { ModalLoadingStatus } from './modal.types';

@Component({
  selector: 'fl-modal',
  template: `
    <div
      class="ModalContainer"
      #modalContainer
      cdkScrollable
      [attr.data-active]="isActive"
      [attr.data-color]="color"
      [attr.data-mobile-fullscreen]="mobileFullscreen"
      [@.disabled]="!isFullscreenMode"
      [@paneAnimation]="state"
      (@paneAnimation.done)="animationDone($event)"
      (mousedown)="handleMousedown($event)"
      (mouseup)="handleMouseup($event)"
      (click)="handleClick()"
    >
      <div
        class="ModalDialog"
        [attr.data-size]="size"
        [attr.data-mobile-fullscreen]="mobileFullscreen"
      >
        <div
          class="ModalContent"
          [attr.data-edge-to-edge]="edgeToEdge"
          [attr.data-color]="color"
          (swiperight)="handleSwipeRight()"
        >
          <div
            *ngIf="mobileFullscreen"
            class="ModalHeader"
            [attr.data-edge-to-edge]="edgeToEdge"
            [flShowMobile]="mobileFullscreen"
          >
            <div
              class="ModalHeader-inner"
              [flSticky]="true"
              [flStickyStatic]="true"
              [flStickyBehaviour]="StickyBehaviour.ALWAYS"
            >
              <fl-view-header
                *ngIf="!customViewHeader"
                [background]="
                  (darkMode$ | flAsync)
                    ? ViewHeaderBackground.DARK
                    : ViewHeaderBackground.LIGHT
                "
              >
                <fl-heading
                  *ngIf="titleTemplate"
                  [color]="HeadingColor.INHERIT"
                  [headingType]="HeadingType.H1"
                  [size]="TextSize.MID"
                  [truncate]="true"
                >
                  <ng-container
                    [ngTemplateOutlet]="titleTemplate"
                  ></ng-container>
                </fl-heading>
              </fl-view-header>

              <ng-container
                *ngIf="customViewHeader && titleTemplate"
                [ngTemplateOutlet]="titleTemplate"
              ></ng-container>

              <fl-container *ngIf="(statusStream$ | flAsync)?.ready === true">
                <fl-toast-alert-container></fl-toast-alert-container>
              </fl-container>
            </div>
          </div>
          <fl-icon
            *ngIf="closeable && (statusStream$ | flAsync)?.ready === true"
            class="ModalCloseButton"
            label="Close"
            i18n-label="Close Modal clickable icon"
            [attr.data-color]="color"
            [flHideMobile]="mobileFullscreen"
            [color]="
              color === ModalColor.LIGHT
                ? IconColor.FOREGROUND
                : IconColor.LIGHT
            "
            [name]="'ui-close'"
            [backdrop]="
              backdropCloseButton
                ? color === ModalColor.LIGHT
                  ? IconBackdrop.WHITE
                  : IconBackdrop.DARK
                : undefined
            "
            [hoverAnimation]="'spin-and-fade'"
            [size]="IconSize.SMALL"
            [id]="'modalCloseButton'"
            (click)="handleEscape()"
          ></fl-icon>
          <ng-container #modalContent></ng-container>

          <ng-container *ngIf="(statusStream$ | flAsync)?.ready === false">
            <fl-spinner
              [size]="SpinnerSize.SMALL"
              [overlay]="true"
            ></fl-spinner>
          </ng-container>

          <ng-container
            *ngIf="(statusStream$ | flAsync)?.errorMessage; let error"
          >
            <fl-modal-error
              [color]="color"
              [edgeToEdge]="edgeToEdge"
              [errorMessage]="error"
              (retry)="retryOpen()"
            ></fl-modal-error>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('paneAnimation', [
      transition('hidden => visible', [useAnimation(slideInHorizontal)]),
      transition('visible => hidden', [useAnimation(slideOutHorizontal)]),
      transition('* => visibleDisabledAnimation', [
        useAnimation(slideInHorizontal, {
          params: {
            startOpacity: '1',
            translateX: '0%',
          },
        }),
      ]),
      transition('* => hiddenDisabledAnimation', [
        useAnimation(slideOutHorizontal, {
          params: {
            finishOpacity: '1',
            translateX: '0%',
          },
        }),
      ]),
    ]),
  ],
})
export class ModalComponent implements OnInit, OnDestroy {
  readonly sizeDefault = ModalSize.LARGE;
  readonly closeableDefault = true;
  readonly edgeToEdgeDefault = false;
  readonly mobileFullscreenDefault = false;
  readonly backdropCloseButtonDefault = false;
  readonly disableAnimationsForRouteChangeDefault = false;
  readonly colorDefault = ModalColor.LIGHT;
  readonly customViewHeaderDefault = false;

  Feature = Feature;
  HeadingColor = HeadingColor;
  HeadingType = HeadingType;
  IconColor = IconColor;
  IconSize = IconSize;
  IconBackdrop = IconBackdrop;
  ModalSize = ModalSize;
  ModalColor = ModalColor;
  SpinnerSize = SpinnerSize;
  StickyBehaviour = StickyBehaviour;
  TextSize = TextSize;
  ViewHeaderBackground = ViewHeaderBackground;
  ViewHeaderType = ViewHeaderType;

  @ViewChild('modalContent', { read: ViewContainerRef, static: true })
  modalContent: ViewContainerRef;
  @ViewChild('modalContainer', { read: ElementRef, static: true })
  modalContainer: ElementRef<HTMLDivElement>;
  isActive = false;

  // To be set by modal service at `open`
  color = this.colorDefault;
  size = this.sizeDefault;
  closeable = this.closeableDefault;
  backdropCloseButton = this.backdropCloseButtonDefault;

  // TODO: T267853 - When edgeToEdge property will be removed, delete "&& (statusStream$ | flAsync)?.ready === true" in the fl-icon (line 60)
  edgeToEdge = this.edgeToEdgeDefault;
  mobileFullscreen = this.mobileFullscreenDefault;
  customViewHeader: boolean = this.customViewHeaderDefault;

  private subscriptions = new Subscription();
  statusStream$: Observable<ModalLoadingStatus>;
  titleTemplate?: TemplateRef<any>;

  canRemoveOverflow = false;
  isBackdropClickedOnMousedown = false;
  isBackdropClickedOnMouseup = false;
  isFullscreenMode = false;
  state = 'hidden';

  /**
   * These are used to prevent the slide in/out animation from showing when a
   * user changes routes. This is to swap modal content without having to slide
   * in/out.
   */
  disableAnimationsForRouteChange = false;
  /**
   * `disableShowAnimation` and `disableHideAnimation` should only be used on
   * the first show/hide respectively.
   */
  disableShowAnimation = false;
  disableHideAnimation = false;
  darkMode$: Observable<boolean>;

  constructor(
    private featureFlagService: FeatureFlagsService,
    private modalService: ModalService,
    @SkipSelf() private injector: Injector,
    private router: Router,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private changeDetectorRef: ChangeDetectorRef,
    private pwa: Pwa,
  ) {}

  ngOnInit(): void {
    this.modalService.setContainer(this.modalContent, this.injector);
    this.subscriptions.add(
      this.modalService.openStream$.subscribe(open => {
        this.isActive = !!open;
        // new modal being opened: read config from modalService
        if (open) {
          const {
            color = this.colorDefault,
            size = this.sizeDefault,
            closeable = this.closeableDefault,
            edgeToEdge = this.edgeToEdgeDefault,
            mobileFullscreen = this.mobileFullscreenDefault,
            backdropCloseButton = this.backdropCloseButtonDefault,
            disableAnimationsForRouteChange = this
              .disableAnimationsForRouteChangeDefault,
            customViewHeader = this.customViewHeaderDefault,
          } = this.modalService.getCurrentConfig();

          this.color = color;
          this.size = size;
          this.closeable = closeable;
          this.edgeToEdge = edgeToEdge;
          this.mobileFullscreen = mobileFullscreen;
          this.isFullscreenMode = this.isMobileView() && this.mobileFullscreen;
          this.backdropCloseButton = backdropCloseButton;
          this.disableAnimationsForRouteChange =
            disableAnimationsForRouteChange;
          this.customViewHeader = customViewHeader;
        }
        this.togglePageScrollState(open);
        this.changeDetectorRef.markForCheck();
      }),
    );
    this.subscriptions.add(
      this.modalService.statusStream$.subscribe(() => {
        if (this.disableShowAnimation) {
          this.state = 'visibleDisabledAnimation';
        } else {
          this.state = 'visible';
        }
        this.changeDetectorRef.markForCheck();
      }),
    );
    this.subscriptions.add(
      this.modalService.beforeCloseStream$.subscribe(() => {
        if (this.isFullscreenMode) {
          if (this.disableHideAnimation) {
            this.state = 'hiddenDisabledAnimation';
          } else {
            this.state = 'hidden';
          }
          this.changeDetectorRef.markForCheck();
        } else {
          this.modalService._destroy();
        }
      }),
    );

    this.statusStream$ = this.modalService.statusStream$;
    this.subscriptions.add(
      this.modalService.titleStream$.subscribe(title => {
        this.titleTemplate = title;
        // T245016: Keeping as detectChanges() as using markForCheck() causes issues with payment sharing e2e test.
        // eslint-disable-next-line local-rules/no-detectchanges-without-detach-reattach
        this.changeDetectorRef.detectChanges();
      }),
    );

    // automatically close modal on route change
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(event => {
            // only close on page changes, and replacing the URL usually is not a full navigation
            const isReplaceUrl =
              this.router.getCurrentNavigation()?.extras?.replaceUrl ?? false;
            return event instanceof NavigationStart && !isReplaceUrl;
          }),
        )
        .subscribe(() => {
          this.disableShowAnimation = this.disableAnimationsForRouteChange;
          this.disableHideAnimation = this.disableAnimationsForRouteChange;
          this.modalService.close({ fromRouteChange: true });
        }),
    );

    // set correct theme base off Primary Nav Bar flag
    this.darkMode$ = this.featureFlagService.getFlag(
      Feature.PRIMARY_NAV_DARK_MODE,
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getScrollBarWidth(): number {
    if (!isPlatformBrowser(this.platformId)) {
      return 0;
    }

    return window.innerWidth - document.body.clientWidth;
  }

  handleMousedown(event: MouseEvent): void {
    this.isBackdropClickedOnMousedown =
      event.target === this.modalContainer.nativeElement;
  }

  handleMouseup(event: MouseEvent): void {
    this.isBackdropClickedOnMouseup =
      event.target === this.modalContainer.nativeElement;
  }

  handleClick(): void {
    // Close only when pressed and released on the backdrop
    if (
      this.closeable &&
      this.isBackdropClickedOnMousedown &&
      this.isBackdropClickedOnMouseup
    ) {
      this.modalService.close();
    }
  }

  animationDone(event: AnimationEvent): void {
    if (event.toState === 'visibleDisabledAnimation') {
      // This is used to ensure that the disableShowAnimation only happens on
      // the first show.
      this.disableShowAnimation = false;
      this.state = 'visible';
    }
    if (event.toState === 'hiddenDisabledAnimation') {
      // This is to ensure that the disableHideAnimation only happens on the
      // first hide.
      this.disableHideAnimation = false;
      this.state = 'hidden';
    }
    if (
      event.toState === 'hidden' ||
      event.toState === 'hiddenDisabledAnimation'
    ) {
      this.modalService._destroy();
    }
  }

  retryOpen(): void {
    this.modalService.retryOpen();
  }

  togglePageScrollState(isModalOpen: boolean): void {
    const target = this.document.body;
    const currentOverflow = target.style.overflow;

    if (isModalOpen && !currentOverflow) {
      this.canRemoveOverflow = true;
    }

    if (isModalOpen) {
      this.disableBodyScroll();
    } else if (!isModalOpen && this.canRemoveOverflow) {
      this.enableBodyScroll();
      this.canRemoveOverflow = false;
    }
  }

  disableBodyScroll(): void {
    // This ensures that the screen does not shift when the scrollbar is removed
    this.renderer.setStyle(
      this.document.body,
      'padding-right',
      `${this.getScrollBarWidth()}px`,
    );
    this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
  }

  enableBodyScroll(): void {
    this.renderer.setStyle(this.document.body, 'padding-right', `0`);
    this.renderer.removeStyle(this.document.body, 'overflow');
  }

  handleSwipeRight(): void {
    if (this.isFullscreenMode && this.pwa.isInstalled()) {
      this.modalService.close();
    }
  }

  private isMobileView(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }

    return window.innerWidth < parseInt(FreelancerBreakpointValues.TABLET, 10);
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(e?: KeyboardEvent): void {
    if (this.closeable) {
      this.modalService.close();
    }
  }
}
