import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import type { Locale } from '@freelancer/config';
import {
  APPS_DOMAINS_MAP,
  APP_NAME,
  Applications,
  AppsDomainsMap,
  SITE_NAME,
} from '@freelancer/config';
import { FACEBOOK_CONFIG, FacebookConfig } from '@freelancer/facebook';
import { Location } from '@freelancer/location';
import { Assets } from '@freelancer/ui/assets';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Response } from 'express';
import { RESPONSE } from 'express.tokens';
import { firstValueFrom } from 'rxjs';
import { SEO_CONFIG } from './seo.config';
import type { LinkTag } from './seo.interface';
import { SeoConfig } from './seo.interface';
import type {
  StructuredData,
  StructuredDataClassName,
} from './structured-data/structured-data.model';

/**
 * A service that can be used to set the title, description, thumbnail image,
 * and other SEO metadata of the current HTML document.
 */
export interface SeoPageConfig {
  // Page title. Must be unique to the page & related to its content.
  // All pages should provide it.
  title: string;
  // Page description. Must be unique to the page & related to its content.
  // All logged-out pages should provide it.
  description?: string;
  // Page thumbnail image (png or jpg)
  // All logged-out content pages should provide it.
  // Most social sites use 1.91:1 thumbnails and recommend min 1200 x 630 pixels
  // /!\ SVGs are not supported by the Open Graph standard.
  image?: string;
  // Page thumbnail image alt text
  // og:image:alt
  imageAlt?: string;
  // Allows to override the path used when generating the canonical URL.
  // By default the page canonical URL is generated from the lowercase version of the current path: set `canonicalPath` to a different path.
  canonicalPath?: string;
  // List of query params that are preserved when generating the canonical URL.
  // By default all the query params are scrapped.
  // Only provide this if you need to preserve some of the query params.
  canonicalQueryParams?: string[];
  // Prevents a page from being indexed & its links followed.
  // Only ever use if you don't want a page to be indexed, e.g. for search
  // pages that show no results.
  noIndex?: boolean;
  // Used with noIndex. Dictates whether links on the page should be followed
  // or not by robots/crawlers. If noIndex is true and this is undefined,
  // this defaults to nofollow.
  follow?: boolean;
  // Page open graph title
  ogTitle?: string;
  // Page open graph description
  ogDescription?: string;
  // Whether to append "| <SiteName>" to the title or not, default is to append
  // Do this when you're including the sitename or 'Freelancer' elsewhere in the title
  includeSiteNameOnTitle?: boolean;
  /**
   * Expected max cacheable duration IN DAYS.
   * The Cache-Control header will set both `max-age` and `stale-while-revalidate` to this.
   * In effect, the page will be cached for up to twice this length.
   *
   * Optional. If unset will use the default cache behavior in server-factory.ts
   */
  maxAgeDays?: number;
}

@UntilDestroy({ className: 'Seo' })
@Injectable({
  providedIn: 'root',
})
export class Seo {
  private readonly STRUCTURED_DATA_CLASS_NAME = 'structured-data';

  constructor(
    @Inject(APPS_DOMAINS_MAP) private appsDomainsMap: AppsDomainsMap,
    @Inject(APP_NAME) private appName: Applications,
    private assets: Assets,
    @Inject(DOCUMENT) private doc: Document,
    private location: Location,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(SEO_CONFIG)
    private seoConfig: SeoConfig,
    @Inject(SITE_NAME) private siteName: string,
    private titleService: Title,
    @Inject(FACEBOOK_CONFIG) private facebookConfig: FacebookConfig,
    @Optional() @Inject(RESPONSE) private response: Response,
  ) {}

  /**
   * Allows to set the page SEO tags
   */
  async setPageTags(config: SeoPageConfig): Promise<void> {
    // Page title
    if ('includeSiteNameOnTitle' in config && !config.includeSiteNameOnTitle) {
      this.titleService.setTitle(`${config.title}`);
    } else {
      this.titleService.setTitle(`${config.title} | ${this.siteName}`);
    }

    // It only make sense to set meta tags on logged-out pages
    if (isPlatformServer(this.platformId)) {
      if (config.maxAgeDays) {
        // max age days * days in seconds. Cache control headers use seconds.
        const cacheTime = config.maxAgeDays * 86_400;
        this.response.set(
          'Cache-Control',
          `public, s-maxage=${cacheTime}, stale-while-revalidate=${cacheTime}`,
        );
      }

      await firstValueFrom(
        this.location.valueChanges().pipe(untilDestroyed(this)),
      ).then(location => {
        const defaultCanonicalUrl = this.location.pathname.toLowerCase();
        let canonicalUrl = `${location.origin}${
          config.canonicalPath
            ? `/${config.canonicalPath}`
            : defaultCanonicalUrl
        }`;

        if (config.canonicalPath) {
          this.addHrefTags(`/${config.canonicalPath}`);
        }

        // Override default canonical link if canonicalQueryParams is provided
        const { canonicalQueryParams } = config;
        if (canonicalQueryParams) {
          const { searchParams } = new URL(this.location.href);
          const filteredSearchParams = new URLSearchParams();
          let hasParam = false;
          searchParams.forEach((name, value) => {
            if (canonicalQueryParams.includes(name)) {
              hasParam = true;
              filteredSearchParams.append(name, value);
            }
          });
          filteredSearchParams.sort();
          if (hasParam) {
            canonicalUrl = `${canonicalUrl}?${filteredSearchParams.toString()}`;
            this.addHrefTags(canonicalUrl);
          }
        }

        if (config.description) {
          // This prevents devs from passing down say the full project
          // description as the meta description tag & having it bloating the
          // <head>.
          // Google will also strip down a meta description, however whilst
          // generally Google only displays under 160 characters they will
          // sometimes display over this, so we strip the description at 170
          // characters to be safe.
          const strippedDescription = config.description.substring(0, 170);
          // Meta description
          this.meta.updateTag({
            name: 'description',
            content: strippedDescription,
          });
          // Open Graph tags
          // The title should not have branding or extraneous information.
          this.meta.updateTag({
            property: 'og:title',
            content: config.ogTitle ? config.ogTitle : config.title,
          });
          this.meta.updateTag({
            property: 'og:type',
            content: 'website',
          });
          this.meta.updateTag({
            property: 'og:site_name',
            content: this.siteName,
          });
          this.meta.updateTag({
            property: 'og:description',
            content: config.ogDescription
              ? config.ogDescription
              : strippedDescription,
          });
          this.meta.updateTag({
            property: 'og:url',
            content: canonicalUrl,
          });
          this.meta.updateTag({
            property: 'og:image',
            content: config.image
              ? config.image
              : this.assets.getUrl(this.seoConfig.defaultMetaImage),
          });
          this.meta.updateTag({
            property: 'og:image:alt',
            content: config.imageAlt ?? $localize`Freelancer Logo`,
          });
          if (this.facebookConfig.appId) {
            this.meta.updateTag({
              property: 'fb:app_id',
              content: this.facebookConfig.appId,
            });
          }
        }
        // Override default robots meta if noIndex is provided
        // If there's already a <meta name="robots"> tag, override it
        if (config.noIndex) {
          this.meta.updateTag({
            name: 'robots',
            content: `noindex, ${config.follow ? 'follow' : 'nofollow'}`,
          });
        } else if (!config.noIndex && config.follow === false) {
          this.meta.updateTag({
            name: 'robots',
            content: `index, nofollow`,
          });
        }
        // no need to handle the index, follow case since that's the default
      });
    }
  }

  /*
   * PRIVATE: only to be used by the Seo component
   * this allows the seo tags to be (re)set to their default values on
   * navigations
   */
  setDefaultPageTags(): void {
    // Reset title to default
    this.titleService.setTitle(this.siteName);

    // It only make sense to set meta tags on logged-out pages
    if (isPlatformServer(this.platformId)) {
      this.addHrefTags(this.location.pathname);

      // Add global link tags, e.g. RSS
      if (this.seoConfig.linkTags) {
        Object.values(this.seoConfig.linkTags).forEach(linkTag => {
          if (linkTag) {
            this.addLinkTag(linkTag);
          }
        });
      }

      // Add global meta tags, e.g. Robot
      if (this.seoConfig.metaTags) {
        Object.values(this.seoConfig.metaTags).forEach(metaTag => {
          if (metaTag) {
            this.meta.updateTag(metaTag);
          }
        });
      }
    }
  }

  /**
   * Adds canonical & alternate href tags to the HEAD
   */
  private addHrefTags(url: string): void {
    // All canonical & alternate URLs must be lowercase
    const lowerCaseUrl = url.toLowerCase();
    firstValueFrom(
      this.location.valueChanges().pipe(untilDestroyed(this)),
    ).then(location => {
      // Set canonical URL. This tells search engines that the specific url is
      // the master copy of that page.
      this.addLinkTag({
        rel: 'canonical',
        href: `${location.origin}${lowerCaseUrl}`,
      });

      // Set alternate URLs (hrefland tags)
      Object.entries(this.appsDomainsMap[this.appName]).forEach(
        ([locale, domain]) => {
          const hreflangId = `hreflang-${locale}`;
          const idExists = this.checkIfIdExisit(hreflangId);
          // en-US is our default locale
          if (locale === 'en' && !idExists) {
            this.addLinkTag({
              id: `${hreflangId}-x-default`,
              rel: 'alternate',
              hreflang: 'x-default',
              href: `https://${domain}${lowerCaseUrl}`,
            });

            this.addLinkTag({
              id: hreflangId,
              rel: 'alternate',
              hreflang: 'en',
              href: `https://${domain}${lowerCaseUrl}`,
            });
          }
          // FIXME: T267853 - some locales do not have custom domains, i.e. aren't
          // crawlable, so including them here would result in duplicate
          // content.
          // We need to either set up proper subdomains for them or switch to
          // another method, e.g. ?lang= query param.
          if (domain === this.appsDomainsMap[this.appName].en) {
            return;
          }

          // Add all link tags with just the language as the `locale`
          if (!locale.includes('-') && !idExists) {
            this.addLinkTag({
              id: hreflangId,
              rel: 'alternate',
              hreflang: locale,
              href: `https://${domain}${lowerCaseUrl}`,
            });
            return;
          }

          // Need to be typecast since it doesn't know if
          // that is `Locale` until runtime.
          const language = locale.split('-')[0] as Locale;

          // If a `language` like `en` or `fr` has a domain which matches
          // locales like `en-us` or `fr-fr`, remove the `en-us` and `fr-fr`
          // since they match with an existing domain.
          if (
            domain !== this.appsDomainsMap[this.appName][language] &&
            !idExists
          ) {
            this.addLinkTag({
              id: hreflangId,
              rel: 'alternate',
              hreflang: locale,
              href: `https://${domain}${lowerCaseUrl}`,
            });
          }
        },
      );
    });
  }

  private checkIfIdExisit(id: string): boolean {
    return this.doc.getElementById(id) != null;
  }

  /**
   * Adds a link tag to the HEAD
   */
  private addLinkTag(tag: LinkTag): void {
    const link = this.doc.createElement('link');
    if (tag.id) {
      link.setAttribute('id', tag.id);
    }

    if (tag.rel) {
      if (tag.rel === 'canonical') {
        this.resetCanonicalUrl();
      }
      link.setAttribute('rel', tag.rel);
    }

    if (tag.href) {
      link.setAttribute(
        'href',
        // this allows link tags hrefs to be specified with root-relative URLs
        // and we'll automatically append the origin here
        tag.href.startsWith('/')
          ? `${this.location.origin}${tag.href}`
          : tag.href,
      );
    }

    if (tag.hreflang) {
      link.setAttribute('hreflang', tag.hreflang);
    }

    if (tag.itemprop) {
      link.setAttribute('itemprop', tag.itemprop);
    }

    if (tag.title) {
      link.setAttribute('title', tag.title);
    }

    if (tag.type) {
      link.setAttribute('type', tag.type);
    }

    this.doc.head.appendChild(link);
  }

  private resetCanonicalUrl(): void {
    Array.from(this.doc.getElementsByTagName('link'))
      .filter(element => element.rel === 'canonical')
      .map(link => {
        if (link) {
          link.remove();
        }
      });
  }

  /**
   * Structured data (sometimes referred to as JSON schema) are pieces of
   * metadata that provide additional information to search engines. Pages
   * with structured data rank better and can be featured in special search
   * snippets.
   *
   * This function stringifies the JSON object, wraps it in a script tag,
   * and appends to the head. This also removes all script tags by class name
   * to make sure that per Structured Data type, only one is added.
   *
   * To add more accepted data types, create a new
   * interface in ./structured-data/structured-data.model.ts and add it to
   * this function's signature.
   *
   * @param config Object following Schema.org vocabulary and JSON-LD markup
   * @param className This will be the additional class name of the script element of the structured data that will be appended in the DOM head
   */
  insertStructuredData(
    config: StructuredData,
    className: StructuredDataClassName,
  ): void {
    if (isPlatformServer(this.platformId)) {
      this.removeStructuredDataByClassName(className);
      const script = this.doc.createElement('script');
      script.setAttribute(
        'class',
        `${this.STRUCTURED_DATA_CLASS_NAME} ${className}`,
      );
      script.type = 'application/ld+json';
      script.text = JSON.stringify(config);
      this.doc.head.appendChild(script);
    }
  }

  /**
   * Removes all script tags from the head with the class name 'structured-data' (ie. all Structured Data)
   *  */
  removeAllStructuredData(): void {
    if (isPlatformServer(this.platformId)) {
      const schemaElements = Array.from(
        this.doc.head.getElementsByClassName(this.STRUCTURED_DATA_CLASS_NAME),
      );
      schemaElements.forEach(element => this.doc.head.removeChild(element));
    }
  }

  /**
   * Removes all structured data elements from the head where is class name is equal to `className`
   *
   * @param className The class name of the script element of the structured data that will removed in the DOM head
   * */
  private removeStructuredDataByClassName(
    className: StructuredDataClassName,
  ): void {
    const schemaElements = Array.from(
      this.doc.head.getElementsByClassName(className),
    );
    schemaElements.forEach(element => this.doc.head.removeChild(element));
  }
}
