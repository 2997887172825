import type {
  CollectionActions,
  CollectionStateSlice,
  Reference,
} from '@freelancer/datastore/core';
import {
  mergeDocuments,
  mergeWebsocketDocuments,
  transformIntoDocuments,
} from '@freelancer/datastore/core';
import { transformAppInstallTrackingRecord } from './app-install-tracking.transformer';
import type { AppInstallTrackingCollection } from './app-install-tracking.types';

export function appInstallTrackingReducer(
  state: CollectionStateSlice<AppInstallTrackingCollection> = {},
  action: CollectionActions<AppInstallTrackingCollection>,
): CollectionStateSlice<AppInstallTrackingCollection> {
  switch (action.type) {
    case 'API_FETCH_SUCCESS': {
      if (action.payload.type === 'appInstallTracking') {
        const { result, ref, order } = action.payload;
        return mergeDocuments<AppInstallTrackingCollection>(
          state,
          transformIntoDocuments(
            result.records,
            transformAppInstallTrackingRecord,
          ),
          order,
          ref,
        );
      }
      return state;
    }
    case 'API_PUSH_SUCCESS': {
      if (action.payload.type === 'appInstallTracking') {
        const { result, ref } = action.payload;
        return mergeWebsocketDocuments<AppInstallTrackingCollection>(
          state,
          transformIntoDocuments([result], transformAppInstallTrackingRecord),
          ref,
        );
      }
      return state;
    }
    case 'WS_MESSAGE': {
      const ref: Reference<AppInstallTrackingCollection> = {
        path: {
          collection: 'appInstallTracking',
          authUid: action.payload.toUserId,
        },
      };

      switch (action.payload.type) {
        case 'appInstallTrackingSet': {
          return mergeWebsocketDocuments<AppInstallTrackingCollection>(
            state,
            transformIntoDocuments(
              [action.payload.data.record],
              transformAppInstallTrackingRecord,
            ),
            ref,
          );
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
}
