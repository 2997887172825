import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import {
  isRequestError,
  type Datastore,
  type RequestError,
} from '@freelancer/datastore';
// FIXME: T235847
// eslint-disable-next-line local-rules/validate-freelancer-imports
import type { InvitationsCollection } from '@freelancer/datastore/collections/invitations';
// eslint-disable-next-line local-rules/validate-freelancer-imports
import type {
  Thread,
  ThreadContext,
  ThreadsCollection,
  ThreadType,
} from '@freelancer/datastore/collections/threads';
// eslint-disable-next-line local-rules/validate-freelancer-imports
import type { User } from '@freelancer/datastore/collections/users';
import type {
  ChatBoxDimensions,
  DraftMessage,
} from '@freelancer/local-storage';
import { LocalStorage } from '@freelancer/local-storage';
import { Location } from '@freelancer/location';
import { Tracking } from '@freelancer/tracking';
import { FreelancerBreakpoints } from '@freelancer/ui/breakpoints';
import { ContainerSize } from '@freelancer/ui/container';
import { isDefined, objectFilter } from '@freelancer/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ContextTypeApi,
  ThreadTypeApi,
} from 'api-typings/messages/messages_types';
import {
  InvitationEntityTypeApi,
  InvitationStatusApi,
  InvitationTypeApi,
} from 'api-typings/users/users';
import type { Observable } from 'rxjs';
import { EMPTY, of, BehaviorSubject, firstValueFrom } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

const chatDraftExpiryDuration = 7 * 24 * 60 * 60 * 1000; // 7 days

export interface Chat {
  /** Users in the chat. Does not need to include the current user. */
  userIds: readonly number[];
  threadType: ThreadType;
  origin: string;
  context?: ThreadContext;
  threadId?: number;
  focus?: boolean;
}

export enum ChatViewState {
  NONE = 'none',
  COMPACT = 'compact',
  FULL = 'full',
}

export enum InboxViewState {
  PAGE = 'page',
  COLUMN = 'column',
}

/**
 * Represents the mode of handling invitations.
 *
 * This type is used to specify whether to include or exclude invitations
 * in a certain context. It accepts two possible string values:
 * - 'INCLUDE_INVITES': Indicates that invitations should be included.
 * - 'EXCLUDE_INVITES': Indicates that invitations should be excluded.
 */
export type InvitationsHandlingMode = 'INCLUDE_INVITES' | 'EXCLUDE_INVITES';

/**
 * Base parameters required for group chat discovery operations.
 * These parameters are common across all modes of operation.
 *
 * @interface BaseGroupChatParams
 * @property {Datastore} datastore - The datastore instance used for querying collections.
 * @property {readonly number[]} userIds - Array of user IDs to check for group chat membership.
 * @property {ThreadContext} threadContext - The context in which to search for group chats.
 * @property {number} loggedInUserId - ID of the currently authenticated user performing the operation.
 */
interface BaseGroupChatParams {
  readonly datastore: Datastore;
  readonly userIds: readonly number[];
  readonly threadContext: ThreadContext;
  readonly loggedInUserId: number;
}

/**
 * Parameters for excluding pending invitations from group chat discovery.
 * This mode filters out group chats that have pending invitations.
 *
 * @extends {BaseGroupChatParams}
 * @property {'EXCLUDE_INVITES'} pendingInvitesMode - Specifies that pending invitations should be excluded.
 *
 * @example
 * const params: ExcludeInvitesParams = {
 *  datastore: myDatastore,
 *  userIds: [1, 2, 3],
 *  threadContext: { type: 'PROJECT', id: 123 },
 *  threadMembers: [1, 2, 3, 4],
 *  loggedInUserId: 1,
 *  pendingInvitesMode: 'EXCLUDE_INVITES'
 * };
 */
type ExcludeInvitesParams = BaseGroupChatParams & {
  readonly pendingInvitesMode: 'EXCLUDE_INVITES';
};

/**
 * Parameters for including only group chats with matching pending invitations.
 * This mode requires valid invitee information to match against pending invitations.
 *
 * @extends {BaseGroupChatParams}
 * @property {'INCLUDE_INVITES'} pendingInvitesMode - Specifies that only chats with matching pending invitations should be included.
 * @property {readonly string[]} validEmailInvitees - Array of valid email addresses that should be checked against pending invitations.
 * @property {readonly User[]} validUsernameInvitees - Array of valid User objects that should be checked against pending invitations.
 *
 * @example
 * const params: IncludeInvitesParams = {
 *   datastore: myDatastore,
 *   userIds: [1, 2, 3],
 *   threadContext: { type: 'PROJECT', id: 123 },
 *   threadMembers: [1, 2, 3, 4],
 *   loggedInUserId: 1,
 *   pendingInvitesMode: 'INCLUDE_INVITES',
 *   validEmailInvitees: ['user@example.com'],
 *   validUsernameInvitees: [{ id: 2, username: 'user2' }]
 * };
 */
type IncludeInvitesParams = BaseGroupChatParams & {
  readonly pendingInvitesMode: 'INCLUDE_INVITES';
  readonly validEmailInvitees: readonly string[];
  readonly validUsernameInvitees: readonly User[];
};

type LiveChatHandler = (chat: Chat) => void;

export enum ChatBoxSize {
  MINIMISED = 'minimised',
  REGULAR = 'regular',
  EXPANDED = 'expanded',
}

// const for new chatbox
export const CHATBOX_DIMENSIONS = {
  [ChatBoxSize.MINIMISED]: {
    height: 48,
    width: 320,
  },
  [ChatBoxSize.REGULAR]: {
    height: 456,
    width: 320,
  },
  [ChatBoxSize.EXPANDED]: {
    height: 675,
    width: 475,
  },
} as const;

@UntilDestroy({ className: 'MessagingChat' })
@Injectable({
  providedIn: 'root',
})
export class MessagingChat {
  private liveChatHandler?: LiveChatHandler;
  private closeAutoPoppedChatsHandler?: () => void;
  private hasOpenChatsHandler?: () => boolean;
  private autoOpenChatDisabled = false;

  private disableToastNotificationsSubject$ = new BehaviorSubject(false);
  isToastDisabled$ = this.disableToastNotificationsSubject$.asObservable();

  private canStartChatSubject$ = new BehaviorSubject(false);
  canStartChat$ = this.canStartChatSubject$.asObservable();

  constructor(
    private location: Location,
    private localStorage: LocalStorage,
    private breakpointObserver: BreakpointObserver,
    private tracking: Tracking,
  ) {}

  canStartChat(): boolean {
    return isDefined(this.liveChatHandler);
  }

  /*
   * Use that to start a new chat session
   */
  startChat({
    userIds,
    threadType,
    origin,
    context,
    threadId,
    focus = true,
    // By default the user gets redirected to the Inbox when the live chat
    // isn't loaded. Use that flag to disable that.
    doNotRedirect = false,
    onlyOpenIfNoChatsOpen = false,
  }: Chat & {
    doNotRedirect?: boolean;
    onlyOpenIfNoChatsOpen?: boolean;
  }): void {
    // If the live chat isn't loaded, redirect to the Inbox to start a chat
    if (!this.liveChatHandler) {
      if (doNotRedirect) {
        return;
      }
      if (threadId) {
        this.location.navigateByUrl(`/messages/thread/${threadId}`);
      } else {
        const url = new URL(`${window.location.origin}/messages/new`);
        const params = url.searchParams;

        params.append('thread_type', threadType);

        if (context) {
          params.append('context_type', context.type);
          if (context.type !== ContextTypeApi.NONE) {
            params.append('context_id', context.id.toString());
          }
        }

        userIds.forEach(uid => {
          params.append('members', uid.toString());
        });
        this.location.navigateByUrl(`${url.pathname}${url.search}`);
      }
    } else {
      // Don't auto open chat if the no_auto_chat_open query param is set,
      // but allow it if the origin is not websocket or contactList
      // (e.g. user clicks on the chat).
      if (
        this.autoOpenChatDisabled &&
        (origin === 'websocket' || origin === 'contactList')
      ) {
        return;
      }

      if (
        onlyOpenIfNoChatsOpen &&
        this.hasOpenChatsHandler &&
        this.hasOpenChatsHandler()
      ) {
        return;
      }
      this.liveChatHandler({
        userIds,
        threadType,
        origin,
        context,
        threadId,
        focus,
      });
    }
  }

  /*
   * This allows the on-page live chat to register itself, when it's loaded,
   * e.g. it might not be loaded on mobile/small screens or high-conversion
   * pages.
   *
   * It should not be used by anyone but the live chat component itself.
   */
  registerMessagingComponentHandlers({
    liveChatHandler,
    closeAutoPoppedChatsHandler,
    hasOpenChatsHandler,
  }: {
    liveChatHandler: LiveChatHandler;
    closeAutoPoppedChatsHandler(): void;
    hasOpenChatsHandler(): boolean;
  }): void {
    this.liveChatHandler = liveChatHandler;
    this.closeAutoPoppedChatsHandler = closeAutoPoppedChatsHandler;
    this.hasOpenChatsHandler = hasOpenChatsHandler;
    this.canStartChatSubject$.next(true);
  }

  /**
   * This allows the live chat component to unregister itself when becoming hidden.
   * It should not be used by anyone but the live chat component itself.
   */
  unregisterMessagingComponentHandlers(): void {
    this.liveChatHandler = undefined;
    this.closeAutoPoppedChatsHandler = undefined;
    this.hasOpenChatsHandler = undefined;
    this.canStartChatSubject$.next(false);
  }

  disableToastNotifications(): void {
    this.disableToastNotificationsSubject$.next(true);
  }

  enableToastNotifications(): void {
    this.disableToastNotificationsSubject$.next(false);
  }

  cleanStoredDraftMessages(): void {
    firstValueFrom(
      this.localStorage
        .get('webappChatDraftMessages')
        .pipe(untilDestroyed(this)),
    ).then(async draftMessagesObject => {
      if (!draftMessagesObject) {
        return;
      }

      const cleanedDraftMsgsObject = objectFilter(
        draftMessagesObject,
        (key: string, dm: DraftMessage | null) => {
          if (!dm || !dm.lastUpdated) {
            return false;
          }
          if (Date.now() - dm.lastUpdated > chatDraftExpiryDuration) {
            return false;
          }
          return true;
        },
      );

      await this.localStorage.set(
        'webappChatDraftMessages',
        cleanedDraftMsgsObject,
      );
    });
  }

  getDimensionLimits(isNewChatbox?: boolean): {
    min: { height: number; width: number };
    max: { height: number; width: number };
  } {
    /**
     * IMPORTANT: Do not move this to constant because
     * the max height and width needs to be recomputed based on the
     * browser's size at the time of the function call
     */
    const newInboxWidgetWidth = 356;
    const chatboxInboxWidgetGap = 8;
    const chatboxSpaceFromEdge = 20; // do not make chatbox too close to the browser edge

    return {
      min: {
        // chatbox cannot be smaller than the default size
        height: CHATBOX_DIMENSIONS[ChatBoxSize.REGULAR].height,
        width: CHATBOX_DIMENSIONS[ChatBoxSize.REGULAR].width,
      },
      max: {
        height: window.innerHeight - (64 + 50) - 40,
        width: Math.min(
          window.innerWidth -
            (newInboxWidgetWidth +
              chatboxInboxWidgetGap +
              chatboxSpaceFromEdge),
          620,
        ),
      },
    };
  }

  /**
   * Given dimensions for a chatbox resize, return the constrained dimensions
   */
  constrainChatboxDimensions(dims: { height: number; width: number }): {
    height: number;
    width: number;
  } {
    const limits = this.getDimensionLimits();

    const newDims = { height: dims.height, width: dims.width };

    if (dims.height > limits.max.height) {
      newDims.height = limits.max.height;
    } else if (dims.height < limits.min.height) {
      newDims.height = limits.min.height;
    }

    if (dims.width > limits.max.width) {
      newDims.width = limits.max.width;
    } else if (dims.width < limits.min.width) {
      newDims.width = limits.min.width;
    }

    return newDims;
  }

  /**
   * Given dimensions for a chatbox resize, return whether or not it's valid
   */
  isValidChatboxDimensions(dims: { height: number; width: number }): boolean {
    const limits = this.getDimensionLimits();

    return !(
      dims.height > limits.max.height ||
      dims.height < limits.min.height ||
      dims.width > limits.max.width ||
      dims.width < limits.min.width
    );
  }

  /**
   * Returns the view state for messaging
   * - `NONE` = no messaging (mobiles and mobile viewports)
   * - `COMPACT` = small contact list (tablet to desktop-xxl)
   * - `FULL` = full-height contact list (desktop-xxl and up)
   */
  getViewState(size?: ContainerSize): Observable<ChatViewState> {
    let fullBreakpoint: FreelancerBreakpoints;
    switch (size) {
      case ContainerSize.DESKTOP_XLARGE:
      case ContainerSize.DESKTOP_XXLARGE:
        fullBreakpoint = FreelancerBreakpoints.DESKTOP_XXXXLARGE;
        break;
      default:
        fullBreakpoint = FreelancerBreakpoints.DESKTOP_XXLARGE;
        break;
    }
    return this.breakpointObserver
      .observe([FreelancerBreakpoints.TABLET, fullBreakpoint])
      .pipe(
        map(state => {
          if (!state.breakpoints[FreelancerBreakpoints.TABLET]) {
            return ChatViewState.NONE;
          }
          if (
            !state.breakpoints[fullBreakpoint] ||
            size === ContainerSize.FLUID
          ) {
            return ChatViewState.COMPACT;
          }
          return ChatViewState.FULL;
        }),
      );
  }

  /**
   * Returns the view state for the messaging inbox
   * - `PAGE` = thread list and chats are separate pages (mobile)
   * - `COLUMN` = thread list and chats are columns on the same page (tablet+)
   */
  getInboxViewState(): Observable<InboxViewState> {
    return this.breakpointObserver
      .observe(FreelancerBreakpoints.TABLET)
      .pipe(
        map(state =>
          state.matches ? InboxViewState.COLUMN : InboxViewState.PAGE,
        ),
      );
  }

  /**
   * Given a list of chats with widths, filters it
   * to only include ones that would fit on-screen.
   */
  fitChatsToScreen<T extends { width: number; dimensions?: ChatBoxDimensions }>(
    chats: T[],
    { offset = 0, gap = 0, useDimensions = false } = {},
  ): T[] {
    const spaceFromEdge = 15; // space from left edge of the browser + scrollbar width
    let currentLeftPos = offset + spaceFromEdge;
    return chats.filter(chat => {
      // we use chat.dimensions for the new chat box
      const width =
        useDimensions && chat.dimensions ? chat.dimensions.width : chat.width;
      // add chat size and check if it would still fit on-screen
      currentLeftPos += width + gap;
      return currentLeftPos < window.innerWidth;
    });
  }

  disableAutoOpenChat(): void {
    this.autoOpenChatDisabled = true;
  }

  closeAutoPoppedChats(): void {
    if (this.closeAutoPoppedChatsHandler) {
      this.closeAutoPoppedChatsHandler();
    }
  }

  /**
   * Finds an existing project-related group chat for the given members
   * while ensuring referential integrity and handling race conditions.
   *
   * This function implements a two-phase reactive stream processing pattern:
   *
   * 1. **Pending Invitations Stream**:
   *    - Queries pending invitations sent by the current user that match the specified criteria:
   *      - Invitation type: `PROJECT_COLLAB_GROUP_CHAT`.
   *      - Invitee entity type: `USER`.
   *      - Status: `PENDING`.
   *    - Transforms the invitations into a deduplicated list of context IDs, which are used
   *      to filter group chats in the next step.
   *
   * 2. **Group Chat Discovery Stream**:
   *    - Searches for group chats in the current thread context where the members match the provided list.
   *    - Uses `equalsIgnoreOrder` for order-independent membership matching.
   *    - Combines results with the pending invitations stream to verify the group chat's membership
   *      and ensure the chat is unique.
   *
   * **Modes of Operation**:
   * - When `EXCLUDE_INVITES` is specified, the function will exclude group chats that match
   *   the pending invitations.
   * - When `INCLUDE_INVITES` is specified, the function will only include group chats that match
   *   the pending invitations.
   *
   * **Return Behavior**:
   * - Returns the matching `Thread` if exactly one unique match is found.
   * - Returns `undefined` if there are no matches or multiple matches.
   * - Returns `RequestError` for errors in either the invitations or group chats queries.
   *
   * @param {ExcludeInvitesParams | IncludeInvitesParams} params - The parameters for finding an existing group chat.
   *
   * @returns An Observable that emits:
   *          - The matching `Thread` if found uniquely.
   *          - `undefined` if no matches or multiple matches are found.
   *          - `RequestError<InvitationsCollection>` or `RequestError<ThreadsCollection>` for errors.
   *
   * @throws Error if multiple group chats are found.
   *
   * @example
   * const existingChat = await firstValueFrom(
   *   this.findExistingGroupChat({
   *     datastore,
   *     loggedInUserId,
   *     userIds: [123, 456],
   *     threadContext: currentThreadContext,
   *     validUsernameInvitees: [{ id: 123, username: 'user1' }],
   *     validEmailInvitees: ['user1@example.com'],
   *     pendingInvitesMode: 'INCLUDE_INVITES',
   *   }),
   * );
   *
   * if (existingChat) {
   *   // Handle the existing group chat
   * } else {
   *   // Create a new group chat
   * }
   */
  findExistingGroupChat(
    params: ExcludeInvitesParams | IncludeInvitesParams,
  ): Observable<
    | Thread
    | RequestError<InvitationsCollection>
    | RequestError<ThreadsCollection>
    | undefined
  > {
    const {
      datastore,
      loggedInUserId,
      userIds,
      threadContext,
      pendingInvitesMode,
    } = params;

    // First, create a collection to find pending invitations that match our criteria
    const pendingInvitationsCollection =
      datastore.collection<InvitationsCollection>('invitations', query =>
        query
          .where(
            'invitationType',
            '==',
            InvitationTypeApi.PROJECT_COLLAB_GROUP_CHAT,
          )
          .where('inviteeEntityType', '==', InvitationEntityTypeApi.USER)
          .where('inviterUserId', '==', loggedInUserId)
          .where('status', '==', InvitationStatusApi.PENDING),
      );

    // Transform the invitations collection into a stream of thread IDs
    const pendingInvitationsThreadIdsOrError$ =
      pendingInvitationsCollection.status$.pipe(
        // Handle the collection status
        switchMap(status => {
          if (status.error) {
            return of(status.error);
          }

          if (!status.ready) {
            return EMPTY;
          }

          return pendingInvitationsCollection.valueChanges();
        }),
        // Transform the invitations into context IDs with proper null handling
        map(invitationsOrError => {
          if (isRequestError<InvitationsCollection>(invitationsOrError)) {
            return invitationsOrError;
          }

          if (invitationsOrError.length === 0) {
            return new Set<number>();
          }

          // Return the invitations without filtering if we're excluding them
          // from the discovery process.
          if (pendingInvitesMode === 'EXCLUDE_INVITES') {
            return new Set<number>(
              invitationsOrError.map(invitation => invitation.contextId),
            );
          }

          const { validUsernameInvitees, validEmailInvitees } = params;

          const validUserIds = new Set(
            validUsernameInvitees.map(user => user.id),
          );
          const validEmails = new Set(validEmailInvitees);

          // Filter and deduplicate the context IDs (thread IDs)
          return new Set<number>(
            invitationsOrError
              .filter(
                invitation =>
                  // Check username invitees
                  (invitation.inviteeEntityId &&
                    validUserIds.has(invitation.inviteeEntityId)) ||
                  // Check email invitees
                  (invitation.inviteeEmail &&
                    validEmails.has(invitation.inviteeEmail)),
              )
              .map(invitation => invitation.contextId),
          );
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

    // Create a collection to find group chats using the thread IDs
    const groupChatsCollection = datastore.collection<ThreadsCollection>(
      'threads',
      query =>
        query
          .where('context', '==', threadContext)
          .where('threadType', '==', ThreadTypeApi.GROUP)
          // Reason for this is that the threads endpoint uses the `IN` MySQL operator
          // which is the equivalent of the `intersects` datastore operator.
          .where('otherMembers', 'intersects', userIds),
    );

    // Transform the group chats collection into a single matching thread
    return pendingInvitationsThreadIdsOrError$.pipe(
      switchMap(threadIdsOrError => {
        if (isRequestError<InvitationsCollection>(threadIdsOrError)) {
          return of(threadIdsOrError);
        }

        // Handle the collection status
        return groupChatsCollection.status$.pipe(
          // Handle the collection status
          switchMap(status => {
            if (status.error) {
              return of(status.error);
            }

            if (!status.ready) {
              return EMPTY;
            }

            return groupChatsCollection.valueChanges();
          }),

          // Filter and transform group chats
          map(groupChatsOrError => {
            if (isRequestError<ThreadsCollection>(groupChatsOrError)) {
              return groupChatsOrError;
            }

            // Filter out any returned group chats that don't match the thread IDs
            const filteredGroupChats = groupChatsOrError.filter(
              chat =>
                (pendingInvitesMode === 'EXCLUDE_INVITES'
                  ? // Exclude group chats that match the pending invitations
                    threadIdsOrError.size === 0 ||
                    !threadIdsOrError.has(chat.id)
                  : // Include only group chats that match the pending invitations
                    threadIdsOrError.has(chat.id)) &&
                // Further filter the group chats by membership to ensure the chat is unique
                // and matches all the provided user IDs.
                //
                // Reason for this is that the threads endpoint uses the `IN` MySQL operator.
                // This means that the threads endpoint will return group chats that match
                // any of the provided user IDs, not all of them.
                chat.otherMembers.length === userIds.length &&
                chat.otherMembers.every(member => userIds.includes(member)),
            );

            if (filteredGroupChats.length === 0) {
              return undefined;
            }

            if (filteredGroupChats.length > 1) {
              const selectedChat = filteredGroupChats.reduce(
                (mostRecent, current) => {
                  const currentEffectiveTime = Math.max(
                    current.timeUpdated,
                    current.timeCreated,
                  );
                  const mostRecentEffectiveTime = Math.max(
                    mostRecent.timeUpdated,
                    mostRecent.timeCreated,
                  );
                  return currentEffectiveTime > mostRecentEffectiveTime
                    ? current
                    : mostRecent;
                },
              );

              this.tracking.trackCustomEvent(
                'Group Chat Discovery',
                'multiple-chats-found',
                {
                  pendingInvitesMode,
                  chatMemberIds: [...userIds],
                  selectedChatId: selectedChat.id,
                  filteredGroupChatIds: filteredGroupChats.map(chat => chat.id),
                },
              );

              return selectedChat;
            }

            return filteredGroupChats[0];
          }),
        );
      }),
    );
  }
}
