import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Empty component to replace the FakeCollectionsWrapper component
 * when running the UI tests (so it doesn't block the view)
 * and in the prod build (to prevent it from being bundled at all)
 */
@Component({
  selector: 'fl-fake-collections',
  standalone: true,
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FakeCollectionsWrapperTestingComponent {}
