import { isPlatformBrowser } from '@angular/common';
import type { AfterViewInit, OnDestroy } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { WebSocketService, ConnectionStatus } from '@freelancer/datastore';
import { FreelancerBreakpointValues } from '@freelancer/ui/breakpoints';
import {
  ToastAlertColor,
  ToastAlertService,
  ToastAlertType,
} from '@freelancer/ui/toast-alert';
import type { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'fl-network-alert',
  template: `
    <fl-toast-alert
      [id]="'fl-websocket-alert'"
      [timeout]="undefined"
      [closeable]="true"
      [color]="ToastAlertColor.DARK"
      [type]="ToastAlertType.ERROR"
    >
      {{ websocketStatusMessage$ | flAsync }}
    </fl-toast-alert>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkAlertComponent implements AfterViewInit, OnDestroy {
  ToastAlertType = ToastAlertType;
  ToastAlertColor = ToastAlertColor;

  private offlineSubscription?: Subscription;
  private websocketDisconnectedSubscription?: Subscription;

  websocketStatusMessage$: Observable<string>;

  constructor(
    private websocket: WebSocketService,
    private toastAlertService: ToastAlertService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId) && !this.isMobileViewport()) {
      this.websocketStatusMessage$ = this.websocket
        .getConnectStatusForUser()
        .pipe(
          map(detail => {
            if (detail.status === ConnectionStatus.CLOSED) {
              return $localize`Unable to connect to chat, retrying in ${detail.retryDelayInSeconds}s`;
            }
            return $localize`You are back online`;
          }),
        );
      this.websocketDisconnectedSubscription = this.websocket
        .getConnectStatusForUser()
        .subscribe(detail => {
          if (detail.status === ConnectionStatus.CLOSED) {
            this.toastAlertService.openById('fl-websocket-alert');
          } else {
            this.toastAlertService.close('fl-websocket-alert');
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.offlineSubscription?.unsubscribe();
    this.websocketDisconnectedSubscription?.unsubscribe();
  }

  private isMobileViewport(): boolean {
    return window.innerWidth < parseInt(FreelancerBreakpointValues.TABLET, 10);
  }
}
