import type { AnimationEvent } from '@angular/animations';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import type { OnDestroy, OnInit, ViewRef } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { RepetitiveSubscription } from '@freelancer/decorators';
import { TimeUtils } from '@freelancer/time-utils';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { SpinnerColor, SpinnerSize } from '@freelancer/ui/spinner';
import { FontColor, TextSize } from '@freelancer/ui/text';
import { Subject, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { ToastAlertService } from './toast-alert.service';
import type { ToastAlertComponentInterface } from './toast-alert.types';
import {
  DynamicToastAlertLinkAction,
  ToastAlertColor,
  ToastAlertType,
} from './toast-alert.types';

@Component({
  selector: 'fl-toast-alert',
  template: `
    <div
      class="ToastAlert"
      [attr.data-type]="type"
      [attr.data-color]="color"
      [@toggleElement]="state"
      (@toggleElement.done)="handleAnimationDone($event)"
    >
      <ng-container [ngSwitch]="type">
        <fl-icon
          class="ToastAlert-icon"
          *ngSwitchCase="ToastAlertType.INFO"
          [color]="IconColor.INHERIT"
          [fillIconFont]="false"
          [name]="'ui-info'"
          [size]="IconSize.SMALL"
          [useIconFont]="true"
          [flMarginRight]="'xsmall'"
        ></fl-icon>
        <fl-icon
          class="ToastAlert-icon"
          *ngSwitchCase="ToastAlertType.ERROR"
          [color]="IconColor.INHERIT"
          [fillIconFont]="false"
          [name]="'ui-error'"
          [size]="IconSize.SMALL"
          [useIconFont]="true"
          [flMarginRight]="'xsmall'"
        ></fl-icon>
        <fl-icon
          class="ToastAlert-icon"
          *ngSwitchCase="ToastAlertType.SUCCESS"
          [color]="IconColor.INHERIT"
          [fillIconFont]="false"
          [size]="IconSize.SMALL"
          [name]="'ui-check-in-circle-v2'"
          [useIconFont]="true"
          [flMarginRight]="'xsmall'"
        ></fl-icon>
        <fl-icon
          class="ToastAlert-icon"
          *ngSwitchCase="ToastAlertType.WARNING"
          [color]="IconColor.INHERIT"
          [fillIconFont]="false"
          [name]="'ui-warning'"
          [size]="IconSize.SMALL"
          [useIconFont]="true"
          [flMarginRight]="'xsmall'"
        ></fl-icon>
        <fl-spinner
          class="ToastAlert-loader"
          *ngSwitchCase="ToastAlertType.LOADING"
          [size]="SpinnerSize.SMALL"
          [flMarginRight]="'xsmall'"
          [color]="
            color === ToastAlertColor.LIGHT
              ? SpinnerColor.GRAY
              : SpinnerColor.LIGHT
          "
        ></fl-spinner>
      </ng-container>
      <fl-text
        class="ToastAlert-content"
        [flMarginRight]="closeable ? 'xsmall' : 'none'"
        [attr.data-closeable]="closeable"
        [color]="
          color === ToastAlertColor.LIGHT
            ? FontColor.FOREGROUND
            : FontColor.LIGHT
        "
      >
        <ng-container *ngIf="content; else contentSlot">
          {{ content }}
          <ng-container *ngIf="action">
            <fl-link
              *ngIf="action.type === 'link'"
              class="ToastAlert-action"
              [link]="action.url"
              [flTrackingLabel]="action.trackingLabel"
              [flTrackingSection]="action.trackingSection"
            >
              {{ action.text }}
            </fl-link>
          </ng-container>
        </ng-container>

        <ng-template #contentSlot>
          <ng-content></ng-content>
        </ng-template>
      </fl-text>
      <fl-button
        *ngIf="closeable || toastAlertService.isAutoCloseDisabled()"
        class="ToastAlert-closeBtn"
        aria-label="Close toast alert"
        i18n-aria-label="Close button for toast alert"
        (click)="handleClose()"
      >
        <fl-icon
          [color]="
            color === ToastAlertColor.LIGHT
              ? IconColor.FOREGROUND
              : IconColor.LIGHT
          "
          [name]="'ui-close'"
          [size]="IconSize.SMALL"
        ></fl-icon>
      </fl-button>
    </div>
  `,
  styleUrls: ['./toast-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toggleElement', [
      state(
        'visible',
        style({
          opacity: '1',
          transform: 'translateY(0)',
        }),
      ),
      state(
        'hidden',
        style({
          opacity: '0',
          transform: 'translateY(-12px)',
        }),
      ),
      transition(
        'visible => hidden',
        animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
      ),
      transition(
        '* => visible',
        animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
      ),
    ]),
  ],
})
export class ToastAlertComponent
  implements ToastAlertComponentInterface, OnInit, OnDestroy
{
  FontColor = FontColor;
  TextSize = TextSize;
  IconColor = IconColor;
  IconSize = IconSize;
  SpinnerColor = SpinnerColor;
  SpinnerSize = SpinnerSize;
  ToastAlertColor = ToastAlertColor;
  ToastAlertType = ToastAlertType;

  element: HTMLElement;
  state: string;

  @RepetitiveSubscription()
  private timer?: Subscription;
  private resetTimerSubject$: Subject<undefined> = new Subject();

  @Input() closeable = false;
  @Input() color = ToastAlertColor.LIGHT;
  @Input() id: string;
  @Input() timeout? = 3000;
  @Input() type = ToastAlertType.INFO;

  /**
   * Only for use with dynamic toast alerts. (.open instead of .openById).
   */
  @Input() content?: string;
  /**
   * Only for use with dynamic toast alerts. (.open instead of .openById).
   */
  @Input() action?: DynamicToastAlertLinkAction;

  @Output() close = new EventEmitter();

  constructor(
    public toastAlertService: ToastAlertService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private el: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private timeUtils: TimeUtils,
  ) {}

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!this.id) {
      console.error('Toast alert must have an id');
      return;
    }

    this.element = this.el.nativeElement;
    this.toastAlertService.add(this, this.id);
    this.state = 'hidden';
  }

  handleClose(): void {
    this.toastAlertService.close(this.id);
    this.close.emit();
  }

  move(position: number): void {
    this.renderer.setStyle(this.element, 'top', `${position}px`);
  }

  startTimer(): void {
    if (this.timeout && !this.toastAlertService.isAutoCloseDisabled()) {
      this.timer = this.resetTimerSubject$
        .pipe(startWith(undefined), this.timeUtils.rxDebounceTime(this.timeout))
        .subscribe(() => this.toastAlertService.close(this.id));
    }
  }

  resetTimer(): void {
    this.resetTimerSubject$.next(undefined);
  }

  unsubscribeTimer(): void {
    if (this.timer) {
      this.timer.unsubscribe();
    }
  }

  toggleVisibility(visibility: string): void {
    this.state = visibility;

    if (!(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.markForCheck();
    }
  }

  handleAnimationDone(event: AnimationEvent): void {
    if (event.fromState === 'visible') {
      this.unsubscribeTimer();
      this.toastAlertService.removeElement(this.id);
    }
  }

  ngOnDestroy(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.toastAlertService.close(this.id);
    this.toastAlertService.removeElement(this.id);
    this.toastAlertService.remove(this.id);
    this.unsubscribeTimer();
  }
}
