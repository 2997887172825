import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { ErrorResponseData } from '@freelancer/freelancer-http';
import {
  FREELANCER_HTTP_CONFIG,
  FreelancerHttpConfig,
} from '@freelancer/freelancer-http/interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Observable } from 'rxjs';
import { firstValueFrom, of } from 'rxjs';
import { catchError, finalize, map, shareReplay, take } from 'rxjs/operators';

@UntilDestroy({ className: 'IpBlacklist' })
@Injectable({
  providedIn: 'root',
})
export class IpBlacklist {
  private inProgressCaptchaCheck$?: Observable<ErrorResponseData<any>>;

  constructor(
    private errorHandler: ErrorHandler,
    private http: HttpClient,
    @Inject(FREELANCER_HTTP_CONFIG)
    private freelancerHttpConfig: FreelancerHttpConfig,
    private router: Router,
  ) {}

  async unblock(captchaResponse: string): Promise<boolean> {
    return firstValueFrom(
      this.http
        .post<{ success: true }>(
          `${this.freelancerHttpConfig.baseUrl}/unblock`,
          {
            'g-recaptcha-response': captchaResponse,
          },
        )
        .pipe(
          map(result => result.success),
          catchError(() => of(false)),
          untilDestroyed(this),
        ),
    );
  }

  // Check if an IP has been flagged and if so redirect to the ban/captcha page
  checkCaptchaRedirect<E = any>(
    error: HttpErrorResponse,
  ): Observable<ErrorResponseData<E | 'UNKNOWN_ERROR'>> {
    const errorResponse = this.formatErrorBody<E | 'UNKNOWN_ERROR'>(error);
    if (error.status !== 403 && error.status !== 429) {
      return of(errorResponse);
    }

    if (this.inProgressCaptchaCheck$) {
      return this.inProgressCaptchaCheck$;
    }

    const captchaCheck$ = this.http
      .get<{
        status: number;
        reason: string;
      }>(`${this.freelancerHttpConfig.baseUrl}/unblock`)
      .pipe(
        take(1),
        map(response => {
          if (response.status === 1) {
            // IP is soft-blocked, navigate to unblock page (similar to 429)
            this.router.navigate(
              [
                '/internal/ip-blacklist-unblock',
                {
                  request_id: error.error.request_id,
                  recaptcha_key: error.error.recaptcha_key,
                  back_url: this.router.url,
                },
              ],
              {
                skipLocationChange: true,
              },
            );
          } else if (response.status === 2) {
            // IP is hard-blocked, navigate to ban page (similar to 403)
            this.router.navigate(
              [
                '/internal/ip-blacklist-ban',
                {
                  request_id: error.error.request_id,
                },
              ],
              {
                skipLocationChange: true,
              },
            );
          }
          return errorResponse;
        }),
        catchError(unblockGetError => {
          this.errorHandler.handleError(unblockGetError);
          return of(errorResponse);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        finalize(() => {
          this.inProgressCaptchaCheck$ = undefined;
        }),
      );

    this.inProgressCaptchaCheck$ = captchaCheck$;

    return captchaCheck$;
  }

  private formatErrorBody<E>(error: HttpErrorResponse): ErrorResponseData<E> {
    // If an error is found, but the request ID isn't present, there is likely
    // an issue with the network.
    if (
      error.error &&
      !error.error.request_id &&
      error.error.error === undefined
    ) {
      return {
        status: 'error',
        errorCode: 'NETWORK_ERROR',
      };
    }

    if (error.error && error.error.error !== undefined) {
      return {
        status: 'error',
        errorCode: error.error.error.code,
        requestId: error.error.request_id,
      };
    }

    // Another type of backend error occured, e.g. External LB or Varnish.
    return {
      status: 'error',
      errorCode: 'UNKNOWN_ERROR',
    };
  }
}
