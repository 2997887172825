/**
 * This Is like Partial only it applies it recursively.
 *
 * This type isn't 100% perfect as it maps `foo: number[]`
 * to `foo: (number | undefined)[] | undefined`
 * rather than `foo: number[] | undefined`
 * but it does map `foo: User` to `foo: RecursivePartial<User>`
 * which is what we want, and it keeps `ReadonlyArray` (somehow)
 * which we definitely want so it's probably fine.
 */

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[] // eslint-disable-line local-rules/readonly-array
    ? RecursivePartial<U>[] // eslint-disable-line local-rules/readonly-array
    : RecursivePartial<T[P]>;
};

export function isObject(item: unknown): item is object {
  return !!item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deeply merges two objects to create a new object, where the second object
 * does not need to have all properties of the first.
 */
export function deepSpread<T>(original: T, delta: RecursivePartial<T>): T {
  if (!isObject(delta)) {
    return delta;
  }

  return {
    ...original,
    ...mapValues<any, keyof T>(delta, (value, key) =>
      deepSpread(((original || {}) as any)[key], value),
    ),
  };
}

// All the (distinct) elements in `as` that isn't in `bs`.
export function setDiff<T>(as: readonly T[], bs: readonly T[]): readonly T[] {
  const setAs = new Set(as);
  const setBs = new Set(bs);
  return Array.from(setAs).filter(a => !setBs.has(a));
}

export enum OrderByDirection {
  ASC,
  DESC,
}

/**
 * Compatible with the Intl.Collator options argument.
 *
 * This options argument allows us to specify ordering that is compatible the Unicode Collation
 * RFC 5051, see https://datatracker.ietf.org/doc/html/rfc5051.
 *
 * You should use these options when supporting ordering by an API whose datastore also supports
 * Unicode Collation (e.g. MySQL utf8mb4_unicode_ci).
 */
export interface OrderByOptions {
  /**
   * Specify which case to order first.
   * - Specify `false` to default to locale
   * - utf8mb4_unicode_ci => upper
   * - utf8mb4_general_ci => upper
   */
  readonly caseFirst: 'upper' | 'lower' | 'false';

  /**
   * Order values numerically. I.e. String numbers are considered as numbers when ordered.
   * - Specify `false` to default to locale
   * - utf8mb4_unicode_ci => false
   * - utf8mb4_general_ci => false
   */
  readonly numeric: boolean | false;
}

/**
 * Default ordering options for Collation settings as "almost" compatible with by MySQL fields
 * using the utf8mb4_unicode_ci and `utf8mb4_general_ci collation.
 *
 * NOTE: Using this sort setting will best match unicode case insensitive ordering however the order
 * of emoji's will not match that of te collation used by utf8mb4_*_ci.
 */
export const ORDER_BY_OPTIONS_UNICODE_CI: OrderByOptions = {
  caseFirst: 'upper',
  numeric: false,
};

/**
 * Type guard for values which are to be compared with strings for sorting.
 */
function isCompatibleWithCollator(value: unknown): value is string {
  return (
    ['string', 'number', 'boolean', 'undefined'].includes(typeof value) ||
    value === null
  );
}

/**
 * Returns a compare function that can be used by Array.prototype.sort()
 */
export function compareMultipleFields<T>([order, ...orders]: readonly {
  readonly field: keyof T;
  readonly direction?: OrderByDirection;
  readonly options?: OrderByOptions;
}[]): (a: T, b: T) => 0 | 1 | -1 {
  if (order === undefined) {
    return () => 0;
  }

  const { field, direction = OrderByDirection.ASC, options } = order;

  return (a: T, b: T) => {
    const aValue = a[field];
    const bValue = b[field];

    if (
      options &&
      isCompatibleWithCollator(aValue) &&
      isCompatibleWithCollator(bValue)
    ) {
      const collator = new Intl.Collator(undefined, {
        caseFirst: options.caseFirst,
        numeric: options.numeric,
      });

      const compareResult =
        direction === OrderByDirection.ASC
          ? collator.compare(aValue, bValue)
          : collator.compare(bValue, aValue);

      return compareResult > 0
        ? 1
        : compareResult < 0
          ? -1
          : compareMultipleFields(orders)(a, b);
    }

    if (aValue < bValue) {
      return direction === OrderByDirection.DESC ? 1 : -1;
    }
    if (aValue > bValue) {
      return direction === OrderByDirection.DESC ? -1 : 1;
    }
    return compareMultipleFields(orders)(a, b);
  };
}

/**
 * Returns an array with no duplicate elements, where uniqueness is defined
 * by the comparator function passed in. Preserves order of the original array.
 * If the comparator is not given, it defaults to '===' equality.
 *
 * Example: Get all unique names from users
 *
 * const names = uniqWith(users, (u1, u2) => u1.name === u2.name).map(u => u.name)
 */
export function uniqWith<T>(
  xs: readonly T[],
  compareFunction: (a: T, b: T) => boolean = (a: T, b: T) => a === b,
): readonly T[] {
  return xs.reduce(
    (acc: T[], x: T) =>
      acc.find(y => compareFunction(x, y)) ? acc : [...acc, x],
    [],
  );
}

export function uniq<T>(xs: readonly T[]): readonly T[] {
  return uniqWith(xs, (x, y) => x === y);
}

export function takeWhile<T, S extends T>(
  array: readonly T[],
  predicate: (value: T, index: number, collection: readonly T[]) => value is S,
): readonly S[];

export function takeWhile<T>(
  array: readonly T[],
  predicate: (value: T, index: number, collection: readonly T[]) => boolean,
): readonly T[];

export function takeWhile<T>(
  array: readonly T[],
  predicate: (value: T, index: number, collection: readonly T[]) => boolean,
): readonly T[] {
  const index = array.findIndex((v, i, c) => !predicate(v, i, c));
  return index === -1 ? array : array.slice(0, index);
}

export function chunk<T>(
  array: readonly T[],
  size = 1,
): readonly (readonly T[])[] {
  const result: (readonly T[])[] = [];
  for (let index = 0; index < array.length; index += size) {
    result.push(array.slice(index, index + size));
  }
  return result;
}

export function objectKeysFilter<K extends PropertyKey, V>(
  object: { readonly [s in K]?: V },
  predicate: (value: V, key: string) => boolean,
): readonly K[] {
  return Object.entries<V | undefined>(object)
    .filter(([key, value]) => value && predicate(value, key))
    .map(([key, value]) => key) as K[];
}

export function arrayIsShallowEqual<T>(
  a?: readonly T[],
  b?: readonly T[],
): boolean {
  return Boolean(
    a &&
      b &&
      a.length === b.length &&
      a.every((entity, index) => entity === b[index]),
  );
}

/**
 * Creates an object with the same keys as `object` and values generated
 * by running each own enumerable string keyed property of `object` thru
 * `iteratee`. The iteratee is invoked with three arguments:
 * (value, key, object).
 *
 * @param {Object} object The object to iterate over.
 * @param {Function} [iteratee=_.identity] The function invoked per iteration.
 * @param {Function} [filter=undefined] The function to filter the iteratee return value.
 * @returns {Object} Returns the new mapped object.
 * @example
 *
 * const users = {
 *   'fred':    { 'user': 'fred',    'age': 40 },
 *   'pebbles': { 'user': 'pebbles', 'age': 1 }
 * };
 *
 * _.mapValues(users, o => o.age);
 * // => { 'fred': 40, 'pebbles': 1 } (iteration order is not guaranteed)
 */
export function mapValues<T, S>(
  object: { readonly [key: string]: T },
  iteratee: (x: T, y: string) => S,
  filter?: (value: S) => boolean,
): {
  readonly [key: string]: S;
};
export function mapValues<T, S, E extends string | number | symbol = string>(
  object: { readonly [key in E]?: T },
  iteratee: (x: T | undefined, y: string) => S,
  filter?: (value: S) => boolean,
): {
  readonly [key in E]?: S;
};
export function mapValues<T, S, E extends string | number | symbol = string>(
  object: { readonly [key in E]?: T },
  iteratee: (x: T | undefined, y: string) => S,
  filter: ((value: S) => boolean) | undefined = undefined,
): {
  readonly [key in E]?: S;
} {
  const result: { [key in E]?: S } = {};
  for (const key of Object.keys(object)) {
    const value = iteratee(object[key as E], key);
    if (!filter || filter(value)) {
      result[key as E] = value;
    }
  }
  return result;
}
