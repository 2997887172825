import { NgModule } from '@angular/core';
import { BackendModule } from '@freelancer/datastore/core';
import { StoreModule } from '@ngrx/store';
import { appInstallTrackingBackend } from './app-install-tracking.backend';
import { appInstallTrackingReducer } from './app-install-tracking.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('appInstallTracking', appInstallTrackingReducer),
    BackendModule.forFeature('appInstallTracking', appInstallTrackingBackend),
  ],
})
export class DatastoreAppInstallTrackingModule {}
