import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { TrackingConsentStatus } from './interface';
import { TrackingConsent } from './tracking-consent.service';
import { TRACKING_CONFIG } from './tracking.config';
import { TrackingConfig } from './tracking.interface';
import type { CustomTrackingEvent, TrackingEventData } from './tracking.model';

// list of facebook standard events
// https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
export enum FacebookStandardEvents {
  PURCHASE = 'Purchase',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  ADD_PAYMENT_INFO = 'AddPaymentInfo',
  CONTACT = 'Contact',
}

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelTracking {
  private isEnabled = false;
  private isInitialized = false;

  constructor(
    private ngZone: NgZone,
    private trackingConsent: TrackingConsent,
    @Inject(TRACKING_CONFIG) private config: TrackingConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  async init(): Promise<void> {
    const trackingConsentStatus =
      await this.trackingConsent.getThirdPartyStatus();
    if (
      this.config.facebookPixelId &&
      isPlatformBrowser(this.platformId) &&
      trackingConsentStatus === TrackingConsentStatus.AUTHORIZED
    ) {
      this.isEnabled = true;
    }
    this.isInitialized = true;
  }

  async trackPageView(): Promise<void> {
    await this.init();
    if (!this.isEnabled) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.loadTrackingSnippet();
      window.fbq('track', 'PageView');
    });
  }

  async trackCustomEvent(
    payload: CustomTrackingEvent & TrackingEventData,
  ): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }

    if (!this.isEnabled) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.loadTrackingSnippet();
      const trackingType = payload.facebookEventName ? 'track' : 'trackCustom';
      const trackingName = payload.facebookEventName || payload.name;
      window.fbq(trackingType, trackingName, {
        ...{
          content_category: payload.section,
        },
        ...(payload.extra_params || {}),
      });
    });
  }

  private loadTrackingSnippet(): void {
    if (window.fbq) {
      return;
    }
    window.fbq = function fbq(): void {
      if (window.fbq.callMethod) {
        // eslint-disable-next-line prefer-rest-params, prefer-spread
        window.fbq.callMethod.apply(window.fbq, arguments);
      } else {
        // eslint-disable-next-line prefer-rest-params
        window.fbq.queue.push(arguments);
      }
    };
    if (!window._fbq) {
      window._fbq = window.fbq;
    }
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
    window.fbq.queue = [];

    const e = document.createElement('script');
    e.async = true;
    e.src = 'https://connect.facebook.net/en_US/fbevents.js';
    const s = document.getElementsByTagName('script')[0];
    (s.parentNode as Node).insertBefore(e, s);

    window.fbq('dataProcessingOptions', ['LDU'], 0, 0); // Enable LDU and have Meta perform geolocation
    window.fbq('init', this.config.facebookPixelId);
  }
}
