import type { AppInstallTrackingRecordApi } from 'api-typings/users/users';
import { AppPlatformTypeApi } from 'api-typings/users/users';
import type { AppInstallTrackingRecord } from './app-install-tracking.model';

export function transformAppInstallTrackingRecord(
  record: AppInstallTrackingRecordApi,
): AppInstallTrackingRecord {
  return {
    id: record.id,
    userId: record.user_id,
    deviceId: record.device_id,
    platformType: record.platform_type,
  };
}

export function isAppPlatformType(
  platformType: string,
): platformType is AppPlatformTypeApi {
  return Object.values(AppPlatformTypeApi).includes(
    platformType as AppPlatformTypeApi,
  );
}
