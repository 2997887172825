import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, withLatestFrom, map } from 'rxjs/operators';
import type { TypedAction } from '../actions';
import { isValidateCacheAndFetchAction } from '../actions';
import { StoreBackend } from '../backend';
import {
  isDocumentRef,
  isTransformedIdsQuery,
  selectDocumentsForReference,
} from '../store.helpers';
import type { StoreState } from '../store.model';

/**
 * Check documents in the store before sending a network request
 * If the cached documents are found and not expired, skip the request.
 * Cache is valid if
 * - For ID queries: all ids are cached
 * - For non-ID queries: query is cached in the query list
 * - For document queries: matching document is cached
 */
@Injectable()
export class CacheInterceptorEffect {
  static readonly CACHE_WINDOW: number = 60 * 1000; // 1 minute

  effect$ = createEffect(() =>
    this.actions$.pipe(
      filter(isValidateCacheAndFetchAction),
      map(action => action.payload),
      withLatestFrom(this.store$),
      map(([payload, storeState]) => {
        const {
          ref,
          ref: {
            path: { collection, authUid },
          },
        } = payload;

        const userStateSlice = storeState[collection]?.[authUid];
        const requestAction: TypedAction = {
          payload,
          type: 'REQUEST_DATA',
        } as TypedAction;
        // If store is empty, send a request
        if (!userStateSlice) {
          return requestAction;
        }

        // Get stored documents
        const documents = selectDocumentsForReference(
          userStateSlice,
          ref,
          this.storeBackend.defaultOrder(collection),
        );
        // documents are not found in the store, send a request
        if (documents === undefined) {
          return requestAction;
        }
        const { documentsWithMetadata } = documents;

        const expirTime = Date.now() - CacheInterceptorEffect.CACHE_WINDOW;
        // For ID queries, it is safe to determine if the record is cached.
        // Then only query the IDs that are missing or not cached.
        if (isTransformedIdsQuery(ref)) {
          const queryIds = ref.path.ids;
          const cached = queryIds.filter(queryId => {
            const matchedCache = documentsWithMetadata.find(
              doc => String(doc.rawDocument.id) === queryId,
            );
            return (
              (matchedCache?.timeFetched !== undefined &&
                matchedCache.timeFetched > expirTime) ||
              (matchedCache?.timeUpdated !== undefined &&
                matchedCache?.timeUpdated > expirTime)
            );
          });
          const missingIds = queryIds.filter(id => !cached.includes(id));
          if (missingIds.length > 0) {
            // only query for the missing ids
            const newPayload = {
              ...payload,
              ref: {
                ...payload.ref,
                path: {
                  ...payload.ref.path,
                  ids: missingIds,
                },
              },
            };
            return {
              payload: newPayload,
              type: 'REQUEST_DATA',
            } as unknown as TypedAction;
          }
          // all ids are cached
          return undefined;
        }

        // For non-ID-only queries, relying on the query list is necessary.
        // The request can be safely skipped if the query was recently updated in the query list.
        if (!isTransformedIdsQuery(ref)) {
          // There is a recently updated matching query stored in the datastore. Do not send request.
          if (
            (documents.timeFetched !== undefined &&
              documents.timeFetched > expirTime) ||
            (documents.timeUpdated !== undefined &&
              documents.timeUpdated > expirTime)
          ) {
            // query is cached
            return undefined;
          }
        }

        // For document queries, check if the raw document is recently updated
        if (isDocumentRef(ref) && documentsWithMetadata.length > 0) {
          const cachedDocument = documentsWithMetadata[0];
          if (
            cachedDocument.timeFetched > expirTime ||
            cachedDocument.timeUpdated > expirTime
          ) {
            // document is cached
            return undefined;
          }
        }
        return requestAction;
      }),
      filter((action): action is TypedAction => action !== undefined),
    ),
  );

  constructor(
    private actions$: Actions<TypedAction>,
    private store$: Store<StoreState>,
    private storeBackend: StoreBackend,
  ) {}
}
