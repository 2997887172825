import { DatePipe } from '@angular/common';
import type { PipeTransform } from '@angular/core';
import { Inject, LOCALE_ID, Pipe } from '@angular/core';
import { LocalizedDateFns } from '@freelancer/ui/localized-date-fns.service';
import { isNumeric } from '@freelancer/utils';

/**
 * Dynamically transforms millescond timestamps to a compact readable
 * version.
 *
 *  If < 1 minute within the date: Just now
 *  If >= 1 minute within the date: x minutes / hours
 *  If a day ago: Yesterday
 *  If < 7 days: Name of day (eg. Mon, Tue, Wed)
 *  If >= 7 days and within the current year: Month and day (eg. Mar 12)
 *  If older than the current year: 'MMM d, y' (eg. Mar 15, 2015)
 *  If in the future: 'MMM d, y' (eg. Mar 15, 2015)
 */

@Pipe({ name: 'dynamicTime' })
export class DynamicTimePipe implements PipeTransform {
  readonly SEC = 1000;
  readonly MINUTE = this.SEC * 60;
  readonly HOUR = this.MINUTE * 60;
  readonly DAY = this.HOUR * 24;
  readonly WEEK = this.DAY * 7;
  readonly MONTH = this.DAY * 30;
  readonly YEAR = this.DAY * 365;

  datePipe = new DatePipe(this.locale);

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private dateFns: LocalizedDateFns,
  ) {}

  getCurrentTime(): Date {
    return new Date();
  }

  transform(millis: number): Promise<string> | undefined {
    if (!isNumeric(millis)) {
      return;
    }

    const dateNow = this.getCurrentTime();
    const inputDate = new Date(millis);

    // If date is in the future, return date MMM d, y
    if (millis > dateNow.getTime()) {
      return Promise.resolve(
        this.datePipe.transform(
          inputDate,
          'mediumDate',
          undefined,
          this.locale,
        ) || '',
      );
    }

    const timePassed = dateNow.getTime() - inputDate.getTime();

    // Within day
    if (
      inputDate.getDate() === dateNow.getDate() &&
      inputDate.getMonth() === dateNow.getMonth() &&
      inputDate.getFullYear() === dateNow.getFullYear()
    ) {
      if (timePassed < this.MINUTE) {
        return Promise.resolve($localize`Just now`);
      }
      return this.dateFns.formatDistanceStrict(inputDate, dateNow);
    }

    // Yesterday
    const yesterday = new Date(dateNow);
    yesterday.setDate(dateNow.getDate() - 1);
    const isYesterday =
      inputDate.getDate() === yesterday.getDate() &&
      inputDate.getMonth() === yesterday.getMonth() &&
      inputDate.getFullYear() === yesterday.getFullYear();
    if (isYesterday) {
      return Promise.resolve($localize`Yesterday`);
    }

    const dateFormat =
      // Within the week
      !isYesterday && timePassed < this.WEEK
        ? 'EE'
        : // More than a week ago, within the current year
          timePassed >= this.WEEK &&
            inputDate.getFullYear() === dateNow.getFullYear()
          ? 'MMM d'
          : // Last year
            dateNow.getFullYear() > inputDate.getFullYear()
            ? 'mediumDate'
            : '';

    return Promise.resolve(
      this.datePipe.transform(inputDate, dateFormat, undefined, this.locale) ||
        '',
    );
  }
}
